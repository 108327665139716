import { httpClient } from '../../infrastructure/admi-client/http-client';
import {
    Building,
    BuildingMetadata,
    BuildingSearch,
    BuildingsMetadata,
    LocationSearch,
    RealEstate,
} from './buildings.dto';

export const admiBuildingsService = {
    async loadBuildingsData(dataSearch: LocationSearch): Promise<RealEstate> {
        const response = await httpClient.get<RealEstate>('/api/v1/buildings', {
            params: {
                lat: dataSearch.location.lat,
                lng: dataSearch.location.lng,
                radius: dataSearch.radius,
                layers: dataSearch.layers,
                settings: {
                    maxSystemCapacityAdjustment: dataSearch.maxSystemCapacityAdjustment,
                },
            },
        });

        return response.data;
    },

    async loadBuildingData(search: BuildingSearch): Promise<Building> {
        const response = await httpClient.get<Building>(`/api/v1/buildings/${search.uuid}`, {
            params: {
                settings: {
                    maxSystemCapacityAdjustment: search.maxSystemCapacityAdjustment,
                    service: search.service,
                },
            },
        });

        return response.data;
    },

    async loadBuildingsMetaData(dataSearch: LocationSearch) {
        const response = await httpClient.get<BuildingsMetadata>('/api/v1/buildings-metadata', {
            params: {
                lat: dataSearch.location.lat,
                lng: dataSearch.location.lng,
                radius: dataSearch.radius,
            },
        });

        return response.data;
    },

    async importBuilding(filePath: string, buildingCode: string) {
        const response = await httpClient.get<BuildingMetadata>('/api/v1/buildings-metadata/import', {
            params: {
                filePath: filePath,
                code: buildingCode,
            },
        });
        return response.data;
    },
};
