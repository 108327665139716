import { httpClient } from '../../infrastructure/admi-client/http-client';
import { OnsiteDataResponseDto, OnsiteListResponseDto, OnsiteSearchDto } from './onsite.dto';

export const admiOnsiteService = {
    getOnsiteList: async (search: OnsiteSearchDto): Promise<OnsiteListResponseDto> => {
        const response = await httpClient.get<OnsiteListResponseDto>(`/api/v1/onsite?page=${search.page}`);
        return response.data;
    },

    getOnsite: async (reference: string): Promise<OnsiteDataResponseDto> => {
        const response = await httpClient.get<OnsiteDataResponseDto>(`/api/v1/onsite/${reference}`);
        return response.data;
    },
};
