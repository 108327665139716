import { Alert, Button, CircularProgress, Grid, Typography } from '@mui/joy';
import { useEffect } from 'react';
import { PvAnalysisBuildingMetadata } from '../pv-analysis.dto';
import { useTranslation } from 'react-i18next';
import { importBuilding, setErrorMessage, setSuccessMessage } from '../pv-analysis.slice';
import { useAppDispatch, useAppSelector } from 'src/redux-app-hooks';
import { useNavigate } from 'react-router';

export default function SelectedBuildingMetadata() {
    const { selectedBuildingMetadata, errorMessage, successMessage, isImportBuildingLoading } = useAppSelector(
        (state) => state.pvAnalysis,
    );

    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setErrorMessage(''));
        dispatch(setSuccessMessage(''));
    }, []);

    function importNewBuilding(buildingMetadata: PvAnalysisBuildingMetadata) {
        dispatch(importBuilding(buildingMetadata));
    }

    return (
        <Grid xs={12} container spacing={3}>
            {isImportBuildingLoading && <CircularProgress />}

            {selectedBuildingMetadata === null && (
                <>
                    <Grid xs={12}>
                        <Alert>{t('Please, select a building')}.</Alert>
                    </Grid>
                    <Grid xs={12}>
                        <Button variant="soft" onClick={() => navigate(-1)}>
                            {t('Return to the previous screen')}
                        </Button>
                    </Grid>
                </>
            )}
            {selectedBuildingMetadata !== null && (
                <Grid key={selectedBuildingMetadata.code} xs={12} container direction="column">
                    <Grid xs={11.5}>
                        <Typography level="h3">
                            {t('Building')} #({selectedBuildingMetadata.code})
                        </Typography>
                    </Grid>
                    <Grid container direction="row" spacing={1}>
                        <Grid xs={5}>
                            <b>{t('Building Function')}:</b>
                        </Grid>
                        <Grid xs={6}>{selectedBuildingMetadata.function}</Grid>
                    </Grid>
                    <Grid container direction="row" spacing={1}>
                        <Grid xs={5}>
                            <b>{t('Building File Path')}:</b>
                        </Grid>
                        <Grid xs={6}>{selectedBuildingMetadata.filePath}</Grid>
                    </Grid>
                    <Grid xs={12} container alignContent="center">
                        <Grid xs={6}>
                            <Button
                                loading={isImportBuildingLoading}
                                onClick={() => importNewBuilding(selectedBuildingMetadata)}
                            >
                                {t('Import Building')}
                            </Button>
                        </Grid>
                        <Grid xs={6}>
                            <Button variant="soft" onClick={() => navigate(-1)}>
                                {t('Return to the previous screen')}
                            </Button>
                        </Grid>
                    </Grid>
                    {errorMessage && (
                        <Grid xs={12}>
                            <Alert color="danger">{t(errorMessage)}</Alert>
                        </Grid>
                    )}
                    {successMessage && (
                        <Grid xs={12}>
                            <Alert color="success">{t(successMessage)}</Alert>
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
}
