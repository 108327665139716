import { Button, CircularProgress, Grid, Stack, Table } from '@mui/joy';
import { Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useSearchParams } from 'react-router';

import { admiOnsiteService } from './admi-onsite.service';
import { OnsiteDto, OnsiteListResponseDto } from './onsite.dto';
import { asGermanDateTime } from 'src/common/date';

function OnsiteListPage() {
    const [onsites, setOnsites] = useState<OnsiteListResponseDto>();
    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const { t } = useTranslation();

    useEffect(() => {
        loadOnsites();
    }, [searchParams]);

    async function loadOnsites() {
        setIsLoading(true);

        const page = Number(searchParams.get('page')) || 1;

        try {
            const response = await admiOnsiteService.getOnsiteList({ page });
            setOnsites(response);
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    function changePage(page: number) {
        setSearchParams({ page: page.toString() });
    }

    return (
        <Grid container direction="row" xs={12} spacing={2} alignContent="flex-start">
            <Grid xs={12}>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Table>
                        <thead>
                            <tr>
                                <th>{t('Title')}</th>
                                <th>{t('Created at')}</th>
                                <th>{t('Actions')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {onsites?.data.map((onsite: OnsiteDto) => (
                                <tr key={onsite.reference}>
                                    <td>{onsite.title}</td>
                                    <td>{asGermanDateTime(onsite.createdAt)}</td>
                                    <td>
                                        <Stack direction="row" spacing={1}>
                                            <Link
                                                to={`/onsite/feasibility-study/create?from_onsite=${onsite.reference}`}
                                            >
                                                <Button variant="outlined">{t('Feasibility study')}</Button>
                                            </Link>
                                            <Link to={`/onsite/${onsite.reference}/tender-form`}>
                                                <Button variant="outlined">{t('BoQ')}</Button>
                                            </Link>
                                        </Stack>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Grid>

            {onsites?.data !== undefined && onsites.data.length > 0 && (
                <Grid xs={12} container justifyContent="center">
                    <Pagination
                        variant="outlined"
                        count={onsites.meta.lastPage}
                        page={Number(searchParams.get('page') || 1)}
                        onChange={(_, page) => changePage(page)}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default connect()(OnsiteListPage);
