import { httpClient2 } from 'src/infrastructure/admi-client/http-client';
import { Contact } from './crm.dto';

export const admiContactService = {
    async searchContacts(filter: string): Promise<Contact[]> {
        const response = await httpClient2.get<Contact[]>('/v1/crm/contacts/search', { params: { filter } });

        return response.data;
    },
};
