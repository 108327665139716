import { useCallback, useEffect, useRef } from 'react';

export default function useDebouncer() {
    const debouncerRef = useRef<number | null>(null);

    const debouncer = useCallback((callback: any, delay: number) => {
        if (debouncerRef.current) {
            clearTimeout(debouncerRef.current);
        }

        debouncerRef.current = window.setTimeout(callback, delay);
    }, []);

    // Clear the timeout on component unmount
    useEffect(() => {
        return () => {
            if (debouncerRef.current) {
                clearTimeout(debouncerRef.current);
            }
        };
    }, []);

    return debouncer;
}
