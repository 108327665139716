/*eslint @typescript-eslint/no-use-before-define: ["off", { "allowNamedExports": true }]*/

/**
 * This is just a convinience wrapper to use routesConfig
 *
 * For now specifically to access all routes as a flat array, other funtions can be added when needed
 */

import { routesConfig } from 'src/routesConfig';

export { getRoutes, getRoutesFlat };

function getRoutes(): Array<any> {
    return routesConfig;
}

function getRoutesFlat(): Array<any> {
    return collect(getRoutes(), []);
}

function collect(list: any, acc: any): Array<any> {
    return list.reduce((accumulator: any, current: any) => {
        accumulator.push(current);

        if (Array.isArray(current.children) && current.children.length) {
            collect(current.children, accumulator);
        }

        return accumulator;
    }, acc);
}
