import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, Option, Select, Switch, Typography, Input } from '@mui/joy';
import { connect } from 'react-redux';

import { OnsiteDataPoint } from 'src/features/onsite/onsite.dto';
import ImageSelector from 'src/common/components/ImageSelector';
import { formFieldBuilder, handleSelect } from 'src/common/formikUtils';
import { ifNotNull } from 'src/common/helpers';

interface Properties {
    formData: { [reference: string]: OnsiteDataPoint };
    formik: any;
}

function FragmentKabelfuehrung(props: Properties) {
    const { formData, formik } = props;

    const formField = (name: string) => formFieldBuilder(formik, name);

    const [cableRoutingDcInside, setCableRoutingDcInside] = useState<string[]>([]);
    const [cableRoutingDcOutside, setCableRoutingDcOutside] = useState<string[]>([]);

    useEffect(() => {
        ifNotNull(formData['cable-routing-dc'], (value) => formik.setFieldValue('KABELFUEHRUNG_DC', value['response']));
        ifNotNull(formData['outside-wall-breakthroughs-number'], (value) =>
            formik.setFieldValue('ANZAHL_WDB_AUSSEN', Number(value['response'])),
        );
        ifNotNull(formData['wall-breakthroughs-above-under-ground'], (value) =>
            formik.setFieldValue('HOEHE_HAUSEINTRITT', value['response']),
        );
        ifNotNull(formData['exterior-wall-type'], (value) => formik.setFieldValue('TYP_AUSSENWAND', value['response']));
        ifNotNull(formData['inside-wall-breakthroughs-number'], (value) =>
            formik.setFieldValue('ANZAHL_WDB_INNEN', Number(value['response'])),
        );
        ifNotNull(formData['excavation-work-necessary'], (value) =>
            formik.setFieldValue('ERDARBEITEN', value['response'] == 'Ja'),
        );

        ifNotNull(formData['cable-routing-dc-inside'], (value) => {
            setCableRoutingDcInside(value['photos'].map((photo: any) => photo.url));
        });

        ifNotNull(formData['cable-routing-dc-outside'], (value) => {
            setCableRoutingDcOutside(value['photos'].map((photo: any) => photo.url));
        });
    }, [formData]);

    return (
        <>
            <Typography level="h3">Kabelführung</Typography>
            <FormControl required>
                <FormLabel>Kabelführung DC</FormLabel>
                <Select value={formik.values.KABELFUEHRUNG_DC} onChange={handleSelect(formik, 'KABELFUEHRUNG_DC')}>
                    <Option value="außen, Fassadenverlegung">außen, Fassadenverlegung</Option>
                    <Option value="innen, mit Hilfe eines vorhandenen Leerrohrs">
                        innen, mit Hilfe eines vorhandenen Leerrohrs
                    </Option>
                </Select>
            </FormControl>

            <FormControl required>
                <FormLabel>Aufnahme Kabelführung DC innen</FormLabel>
                <ImageSelector
                    name="AUFNAHME_KABELFUEHRUNG_DC_INNEN"
                    formik={formik}
                    imageUrls={cableRoutingDcInside}
                    multiple={true}
                    required={true}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Kabelführung DC außen</FormLabel>
                <ImageSelector
                    name="AUFNAHME_KABELFUEHRUNG_DC_AUSSEN"
                    formik={formik}
                    imageUrls={cableRoutingDcOutside}
                    multiple={true}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Kabelführung DC außen (in m)</FormLabel>
                <Input
                    type="number"
                    placeholder="Kabelführung DC außen (in m)"
                    {...formField('KABELFUEHRUNG_DC_AUSSEN')}
                />
            </FormControl>

            <FormControl>
                <FormLabel>Aufnahme Hauseintritt</FormLabel>
                <ImageSelector name="AUFNAHME_HAUSEINTRITT" formik={formik} />
            </FormControl>

            <FormControl required>
                <FormLabel>Anzahl Wanddurchbrüche außen</FormLabel>
                <Input type="number" placeholder="Anzahl Wanddurchbrüche außen" {...formField('ANZAHL_WDB_AUSSEN')} />
            </FormControl>

            <FormControl>
                <FormLabel>Wanddurchbruch überirdisch/unterirdisch</FormLabel>
                <Select value={formik.values.HOEHE_HAUSEINTRITT} onChange={handleSelect(formik, 'HOEHE_HAUSEINTRITT')}>
                    <Option value="überirdisch">überirdisch</Option>
                    <Option value="unterirdisch">unterirdisch</Option>
                </Select>
            </FormControl>

            <FormControl>
                <FormLabel>Typ der Außenwand</FormLabel>
                <Input type="text" placeholder="Typ der Außenwand" {...formField('TYP_AUSSENWAND')} />
            </FormControl>

            <FormControl required>
                <FormLabel>Anzahl Wanddurchbrüche innen</FormLabel>
                <Input type="number" placeholder="Anzahl Wanddurchbrüche innen" {...formField('ANZAHL_WDB_INNEN')} />
            </FormControl>

            <Typography
                component="label"
                endDecorator={
                    <Switch
                        sx={{ ml: 1 }}
                        checked={formik.values.ERDARBEITEN}
                        onChange={(event) => formik.setFieldValue('ERDARBEITEN', event.target.checked)}
                    />
                }
            >
                Erdarbeiten notwendig
            </Typography>
        </>
    );
}

const mapStateToProps = function (state: any) {
    return {
        formData: state.onsite.formData,
    };
};

export default connect(mapStateToProps)(FragmentKabelfuehrung);
