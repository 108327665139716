import { Button, InputAdornment, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchIcon from '@mui/icons-material/Search';
import { resetBuildingSelection, setLocation } from '../pv-analysis.slice';
import { useAppDispatch, useAppSelector } from 'src/redux-app-hooks';
import useGoogleMapsApiLoader from 'src/infrastructure/google/useGoogleMapsApiLoader';

export default function Search() {
    const { location, isMapLoading } = useAppSelector((state) => state.pvAnalysis);
    const dispatch = useAppDispatch();

    const [address, setAddress] = useState('');
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

    const loader = useGoogleMapsApiLoader();

    const { t } = useTranslation();

    useEffect(() => {
        console.debug('Search', location);
        loadGeocoder();
    }, []);

    async function loadGeocoder() {
        const { Geocoder } = (await loader.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
        setGeocoder(new Geocoder());
    }

    async function handleChangeAddress(e: any) {
        if ((e.key === 'Enter' || e.type === 'click') && geocoder !== undefined) {
            const geocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode({ address });
            console.debug('address.geocoderResponse', geocoderResponse);

            if (geocoderResponse.results.length > 0) {
                dispatch(
                    setLocation({
                        lat: geocoderResponse.results[0].geometry.location.lat(),
                        lng: geocoderResponse.results[0].geometry.location.lng(),
                        address: geocoderResponse.results[0].formatted_address,
                    }),
                );
                dispatch(resetBuildingSelection());
            }
        }
    }

    return (
        <Stack direction="row" spacing={1}>
            <TextField
                placeholder={t('Search')}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                onKeyDown={(e) => handleChangeAddress(e)}
                slotProps={{
                    input: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    },
                }}
                fullWidth
                size="small"
                disabled={isMapLoading}
            />
            <Button variant="contained" color="primary" onClick={(e) => handleChangeAddress(e)} disabled={isMapLoading}>
                {t('Search')}
            </Button>
        </Stack>
    );
}
