import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { SolarPotentialServiceType } from 'src/features/real-estate/buildings.dto';

export interface State {
    useQAPVCalculator: boolean;
    solarService: SolarPotentialServiceType;
}

const initialState: State = {
    useQAPVCalculator: false,
    solarService: SolarPotentialServiceType.GOOGLE,
};

const featureFlagsSlice = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {
        setUseQAPVCalculator: (state, action: PayloadAction<boolean>) => {
            state.useQAPVCalculator = action.payload;
            Sentry.setTag('qaPVCalculator', action.payload);
        },
        setSolarService: (state, action: PayloadAction<SolarPotentialServiceType>) => {
            state.solarService = action.payload;
            Sentry.setTag('solarService', action.payload);
        },
    },
});

export const { setUseQAPVCalculator, setSolarService } = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
