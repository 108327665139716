import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_API_KEY } from 'src/appConfig';

const apiKey = GOOGLE_API_KEY || '';

let loader: Loader | undefined = undefined;

function useGoogleMapsApiLoader() {
    if (loader === undefined) {
        loader = new Loader({
            apiKey,
            version: 'weekly',
        });
    }

    return loader;
}

export default useGoogleMapsApiLoader;
