export function formatToGermanNumber(numStr: any, digits = -1) {
    if (digits > -1) {
        return Number(numStr).toLocaleString('de-DE', { minimumFractionDigits: digits, maximumFractionDigits: digits });
    }

    return Number(numStr).toLocaleString('de-DE');
}

export function parseGermanNumber(germanNumber: string): number {
    if (!germanNumber) {
        return NaN;
    }

    const num = germanNumber
        .replace(/\./g, '') // remove number separator
        .replace(',', '.'); // replace decimal separator

    return parseFloat(num);
}
