import { httpClient2 } from 'src/infrastructure/admi-client/http-client';
import {
    OnsiteVisitsResponseDto,
    OnsiteVisitSearchDto,
    OnsiteVisitQuestionDto,
    SubmittedQuestionDto,
    OnsiteVisitTemplateDto,
} from './onsite-visit.dto';

export const admiOnsiteVisitService = {
    getOnsiteVisits: async (search: OnsiteVisitSearchDto): Promise<OnsiteVisitsResponseDto> => {
        console.debug('search', search);

        const params: Record<string, string | number> = {
            page: search.page,
            size: search.size,
            sort: search.sort,
        };

        if (search.status) params.status = search.status;
        if (search.templateId) params.templateId = search.templateId;
        if (search.visitDateFrom) params.from = new Date(search.visitDateFrom).toISOString();
        if (search.visitDateTo) params.to = new Date(search.visitDateTo).toISOString();

        const response = await httpClient2.get<OnsiteVisitsResponseDto>('/v1/onsite-visits', { params });

        return response.data;
    },

    getOnsiteVisitQuestions: async (externalId: string) => {
        const response = await httpClient2.get<OnsiteVisitQuestionDto[]>(`/v1/onsite-visits/${externalId}/questions`);
        return response.data;
    },

    submitOnsiteVisit: async (externalId: string, questions: SubmittedQuestionDto[]) => {
        const response = await httpClient2.post(`/v1/onsite-visits/${externalId}/submit`, {
            questions: questions,
        });
        return response.data;
    },

    getOnsiteTemplates: async (): Promise<OnsiteVisitTemplateDto[]> => {
        const response = await httpClient2.get<OnsiteVisitTemplateDto[]>('/v1/onsite-templates');
        return response.data;
    },
};
