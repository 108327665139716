import { Button, CircularProgress, Grid, IconButton, List, ListItem, Stack, Table, Typography } from '@mui/joy';
import { Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import { PVAnalysisDto, PVAnalysisVariantDto } from './pv-analysis.dto';
import { admiPVAnalysisService } from './admi-pv-analysis.service';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router';
import { KeyboardArrowDown } from '@mui/icons-material';
import { PaginatedResponseDto } from 'src/infrastructure/admi-client/response.dto';
import { asGermanDateTime } from 'src/common/date';

function PVAnalysisListPage() {
    const [pvAnalysis, setPVAnalysis] = useState<PaginatedResponseDto<PVAnalysisDto>>();
    const [pvAnalysisVariants, setPVAnalysisVariants] = useState<{
        [key: string]: PaginatedResponseDto<PVAnalysisVariantDto>;
    }>();

    const [open, setOpen] = useState<{ [key: string]: boolean }>({});

    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams({ page: '1', perPage: '15' });

    const { t } = useTranslation();

    useEffect(() => {
        loadPVAnalysis();
    }, [searchParams]);

    useEffect(() => {
        loadPVAnalysisVariants();
    }, [pvAnalysis]);

    async function loadPVAnalysis() {
        setIsLoading(true);

        const page = Number(searchParams.get('page')) || 1;
        const perPage = Number(searchParams.get('perPage')) || 15;

        try {
            const response = await admiPVAnalysisService.getPVAnalysisList({ page, perPage });
            setPVAnalysis(response);
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    async function loadPVAnalysisVariants() {
        setIsLoading(true);
        try {
            const variants: { [key: string]: PaginatedResponseDto<PVAnalysisVariantDto> } = pvAnalysisVariants || {};
            for (const analysis of pvAnalysis?.data || []) {
                const response = await admiPVAnalysisService.getPVAnalysisVariants(analysis.uuid, {
                    page: 1,
                    perPage: 30,
                });
                variants[analysis.uuid] = response;
            }
            setPVAnalysisVariants(variants);
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    function changePage(page: number) {
        setSearchParams({ page: page.toString() });
    }

    async function handleDeleteVariant(uuid: string) {
        if (window.confirm(t('Are you sure you want to delete this variant?'))) {
            await admiPVAnalysisService.deletePVAnalysisVariant(uuid);
            loadPVAnalysis();
        }
    }

    return (
        <Grid container direction="row" xs={12} spacing={2}>
            <Grid xs={12}>
                <Typography level="h2">{t('PV Analysis')}</Typography>
            </Grid>

            <Grid xs={12} container direction="row" justifyContent="right">
                <Link to="/pv-analysis/create">
                    <Button variant="solid">{t('Create New Potential Analysis')}</Button>
                </Link>
            </Grid>

            <Grid xs={12}>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <List
                        size="sm"
                        sx={(theme) => ({
                            // Gatsby colors
                            '--joy-palette-primary-plainColor': '#8a4baf',
                            '--joy-palette-neutral-plainHoverBg': 'transparent',
                            '--joy-palette-neutral-plainActiveBg': 'transparent',
                            '--joy-palette-primary-plainHoverBg': 'transparent',
                            '--joy-palette-primary-plainActiveBg': 'transparent',
                            [theme.getColorSchemeSelector('dark')]: {
                                '--joy-palette-text-secondary': '#635e69',
                                '--joy-palette-primary-plainColor': '#d48cff',
                            },
                            '--List-insetStart': '32px',
                            '--ListItem-paddingY': '0px',
                            '--ListItem-paddingRight': '16px',
                            '--ListItem-paddingLeft': '21px',
                            '--ListItem-startActionWidth': '0px',
                            '--ListItem-startActionTranslateX': '-50%',
                            '& [class*="startAction"]': {
                                color: 'var(--joy-palette-text-tertiary)',
                            },
                        })}
                    >
                        {pvAnalysis?.data?.map((analysis) => (
                            <ListItem
                                nested
                                key={analysis.uuid}
                                sx={{ my: 1 }}
                                startAction={
                                    <IconButton
                                        variant="plain"
                                        size="sm"
                                        color="neutral"
                                        onClick={() => setOpen({ ...open, [analysis.uuid]: !open[analysis.uuid] })}
                                    >
                                        <KeyboardArrowDown
                                            sx={[
                                                open[analysis.uuid]
                                                    ? { transform: 'initial' }
                                                    : { transform: 'rotate(-90deg)' },
                                            ]}
                                        />
                                    </IconButton>
                                }
                            >
                                <ListItem>
                                    <IconButton
                                        variant="plain"
                                        size="sm"
                                        color="neutral"
                                        onClick={() => setOpen({ ...open, [analysis.uuid]: !open[analysis.uuid] })}
                                    >
                                        <Typography
                                            level="inherit"
                                            sx={[
                                                open
                                                    ? { fontWeight: 'bold', color: 'text.primary' }
                                                    : { fontWeight: null, color: 'inherit' },
                                            ]}
                                        >
                                            {analysis.name}
                                        </Typography>
                                    </IconButton>
                                </ListItem>
                                {open[analysis.uuid] && (
                                    <List sx={{ '--ListItem-paddingY': '8px' }}>
                                        <ListItem>
                                            <Table>
                                                <tbody>
                                                    {pvAnalysisVariants?.[analysis.uuid]?.data.map((variant) => (
                                                        <tr key={variant.uuid}>
                                                            <td width="40%">{variant.name}</td>
                                                            <td width="30%">{variant.author}</td>
                                                            <td width="15%">
                                                                {asGermanDateTime(variant.createdAt.toString())}
                                                            </td>
                                                            <td width="15%">
                                                                <Stack direction="row" spacing={1}>
                                                                    <Link
                                                                        to={`/pv-analysis/pv-analysis-variation/${variant.uuid}/details`}
                                                                    >
                                                                        <Button variant="soft">{t('Open')}</Button>
                                                                    </Link>
                                                                    <Button
                                                                        variant="solid"
                                                                        color="danger"
                                                                        onClick={() =>
                                                                            handleDeleteVariant(variant.uuid)
                                                                        }
                                                                    >
                                                                        {t('Delete')}
                                                                    </Button>
                                                                </Stack>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </ListItem>
                                    </List>
                                )}
                            </ListItem>
                        ))}
                    </List>
                )}
            </Grid>

            <Grid xs={12} container justifyContent="center">
                {pvAnalysis !== undefined && pvAnalysis.data.length > 0 && (
                    <Pagination
                        variant="outlined"
                        count={pvAnalysis.meta.lastPage}
                        page={Number(searchParams.get('page') || 1)}
                        onChange={(_, page) => changePage(page)}
                    />
                )}
            </Grid>
        </Grid>
    );
}

export default PVAnalysisListPage;
