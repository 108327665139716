import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { admiOnsiteVisitService } from './onsite-visit.service';
import { BuildingSearch, GeoLocation, LocationSearch } from 'src/features/real-estate/buildings.dto';
import {
    Building,
    OnsiteVisitQuestionDto,
    OnsiteVisitTemplateDto,
    RequestStatus,
    SubmittedQuestionDto,
} from './onsite-visit.dto';
import { admiBuildingsService } from 'src/features/real-estate/admi-buildings.service';

export const loadOnSiteVisitForm = createAsyncThunk<OnsiteVisitQuestionDto[], string>(
    'onsiteVisit/loadOnSiteVisitForm',
    async (externalId, { rejectWithValue }) => {
        try {
            const response = await admiOnsiteVisitService.getOnsiteVisitQuestions(externalId);
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message || 'Failed to load onsite visit questions');
        }
    },
);

export const loadRealEstateData = createAsyncThunk(
    'onsiteVisit/loadRealEstateData',
    async (dataSearch: LocationSearch) => {
        const response = await admiBuildingsService.loadBuildingsData(dataSearch);

        return response.buildings.map(({ name, code, geometry, id, roof }) => ({
            name,
            code,
            geometry,
            id,
            roof: roof
                ? {
                      id: roof.id,
                      code: roof.code,
                      bbox: roof.bbox,
                      area: roof.area,
                      roofSegments: roof.roofSegments.map(
                          ({ id, code, bbox, area, tiltDegrees, azimuthDegrees, center }) => ({
                              id,
                              code,
                              bbox,
                              area,
                              tiltDegrees,
                              azimuthDegrees,
                              center,
                          }),
                      ),
                      source: roof.source,
                  }
                : null,
        }));
    },
);

export const loadBuildingToSelection = createAsyncThunk(
    'onsiteVisit/selectBuilding',
    async (buildingSearch: BuildingSearch, _thunkApi) => {
        const building = await admiBuildingsService.loadBuildingData(buildingSearch);

        return {
            name: building.name,
            buildingFunction: building.buildingFunction,
            code: building.code,
            geometry: building.geometry,
            id: building.id,
            roof: building.roof
                ? {
                      id: building.roof.id,
                      code: building.roof.code,
                      bbox: building.roof.bbox,
                      area: building.roof.area,
                      roofSegments: building.roof.roofSegments.map(
                          ({ id, code, bbox, area, tiltDegrees, azimuthDegrees, center }) => ({
                              id,
                              code,
                              bbox,
                              area,
                              tiltDegrees,
                              azimuthDegrees,
                              center,
                          }),
                      ),
                      source: building.roof.source,
                  }
                : null,
        };
    },
);

export const submitOnSiteVisit = createAsyncThunk<
    void,
    { externalId: string; questions: SubmittedQuestionDto[] },
    { rejectValue: string }
>('onsiteVisit/submitOnSiteVisit', async ({ externalId, questions }, { rejectWithValue }) => {
    try {
        await admiOnsiteVisitService.submitOnsiteVisit(externalId, questions);
    } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Failed to submit onsite visit');
    }
});

export const loadOnsiteTemplates = createAsyncThunk<OnsiteVisitTemplateDto[], void>(
    'onsiteVisit/loadOnsiteTemplates',
    async (_, { rejectWithValue }) => {
        try {
            const response = await admiOnsiteVisitService.getOnsiteTemplates();
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message || 'Failed to load onsite templates');
        }
    },
);

export interface State {
    location: GeoLocation | null;
    errorMessage: string;
    successMessage: string;
    buildings: Building[];
    selectedBuildings: Building[] | null;
    isMapLoading: boolean;
    onSiteVisitQuestions: OnsiteVisitQuestionDto[] | null;
    highlightedBuilding: string | null;
    submissionStatus: RequestStatus | null;
    templates: OnsiteVisitTemplateDto[];
}

const initialState: State = {
    location: {
        address: 'Oberbilker Allee 244, 40227 Düsseldorf',
        lat: 51.2108431,
        lng: 6.802931999999999,
    },
    errorMessage: '',
    successMessage: '',
    buildings: [],
    selectedBuildings: null,
    isMapLoading: false,
    onSiteVisitQuestions: null,
    highlightedBuilding: null,
    submissionStatus: null,
    templates: [],
};

const onsiteVisitSlice = createSlice({
    name: 'onsiteVisit',
    initialState,
    reducers: {
        setLocation(state, action: PayloadAction<GeoLocation>) {
            state.location = action.payload;
        },
        setErrorMessage(state, action: PayloadAction<string>) {
            state.errorMessage = action.payload;
        },
        setSuccessMessage(state, action: PayloadAction<string>) {
            state.successMessage = action.payload;
        },
        setSelectedBuildings(state, action: PayloadAction<Building[] | null>) {
            state.selectedBuildings = action.payload;
        },
        setIsMapLoading(state, action: PayloadAction<boolean>) {
            state.isMapLoading = action.payload;
        },
        resetBuildingSelection(state) {
            state.buildings = [];
        },
        setHighlightedBuilding: (state, action: PayloadAction<string | null>) => {
            console.log('setHighlightedBuilding', action.payload);
            state.highlightedBuilding = action.payload;
        },
        setSubmissionStatus: (state, action: PayloadAction<RequestStatus | null>) => {
            state.submissionStatus = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadOnSiteVisitForm.fulfilled, (state, action: PayloadAction<OnsiteVisitQuestionDto[]>) => {
                state.onSiteVisitQuestions = action.payload;
            })
            .addCase(loadOnSiteVisitForm.rejected, (state, action) => {
                state.errorMessage = action.payload as string;
            })
            .addCase(loadRealEstateData.pending, (state) => {
                state.isMapLoading = true;
            })
            .addCase(loadRealEstateData.fulfilled, (state, action) => {
                state.isMapLoading = false;
                state.buildings = action.payload;
            })
            .addCase(loadRealEstateData.rejected, (state, action) => {
                state.isMapLoading = false;
                state.errorMessage = action.error.message ?? action.error.code ?? 'Unknown error';
            })
            .addCase(submitOnSiteVisit.pending, (state) => {
                state.submissionStatus = {
                    isLoading: true,
                    message: null,
                    type: null,
                };
            })
            .addCase(submitOnSiteVisit.fulfilled, (state) => {
                state.isMapLoading = false;
                state.submissionStatus = {
                    isLoading: false,
                    message: 'Onsite visit submitted successfully!',
                    type: 'success',
                };
            })
            .addCase(submitOnSiteVisit.rejected, (state, action) => {
                state.isMapLoading = false;
                state.submissionStatus = {
                    isLoading: false,
                    message: action.payload as string,
                    type: 'error',
                };
            })
            .addCase(loadOnsiteTemplates.pending, (state) => {
                state.templates = [];
            })
            .addCase(loadOnsiteTemplates.fulfilled, (state, action: PayloadAction<OnsiteVisitTemplateDto[]>) => {
                state.templates = action.payload;
            })
            .addCase(loadOnsiteTemplates.rejected, (state, action) => {
                state.errorMessage = action.payload as string;
            });
    },
});
export const {
    setLocation,
    setErrorMessage,
    setSuccessMessage,
    setSelectedBuildings,
    setIsMapLoading,
    resetBuildingSelection,
    setHighlightedBuilding,
    setSubmissionStatus,
} = onsiteVisitSlice.actions;

export default onsiteVisitSlice.reducer;
