import { useCallback, useEffect, useState } from 'react';
import {
    CircularProgress,
    Button,
    Grid,
    Card,
    Typography,
    FormControl,
    FormLabel,
    Input,
    Select,
    Option,
    Snackbar,
    Alert,
} from '@mui/joy';
import {
    loadOnSiteVisitForm,
    setHighlightedBuilding,
    setSelectedBuildings,
    submitOnSiteVisit,
    setSubmissionStatus,
    loadBuildingToSelection,
} from './onsite-visit.slice';
import { useAppDispatch, useAppSelector } from 'src/redux-app-hooks';
import Map from './components/Map';
import { useParams } from 'react-router';
import Search from './components/Search';
import { useTranslation } from 'react-i18next';
import { Building, SubmittedQuestionDto } from './onsite-visit.dto';
import styles from './OnsiteVisitSubmitPage.module.css';

export default function OnsiteVisitSubmitPage() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { onSiteVisitQuestions, selectedBuildings, submissionStatus } = useAppSelector((state) => state.onsiteVisit);
    const solarService = useAppSelector((state) => state.featureFlags.solarService);

    const [questionEntityMap, setQuestionEntityMap] = useState<Record<string, string[]>>({});
    const [errors, setErrors] = useState<Record<string, boolean>>({});

    useEffect(() => {
        if (id) {
            dispatch(loadOnSiteVisitForm(id));
        }
        dispatch(setSelectedBuildings([]));
    }, [dispatch, id]);

    const handleBuildingSelect = useCallback(
        async (selected: Building[], addedBuilding: Building | null) => {
            if (addedBuilding) {
                try {
                    const buildingDetails = await dispatch(
                        loadBuildingToSelection({
                            uuid: addedBuilding.code,
                            service: solarService,
                        }),
                    ).unwrap();

                    // Replace the existing building in selectedBuildings with the detailed one
                    const updatedSelected = selected.map((b) => (b.id === buildingDetails.id ? buildingDetails : b));
                    dispatch(setSelectedBuildings(updatedSelected));
                } catch (error) {
                    console.error('Failed to load building details', error);
                }
            } else {
                dispatch(setSelectedBuildings(selected));
            }
        },
        [dispatch, solarService],
    );

    useEffect(() => {
        if (selectedBuildings?.length === 1) {
            const singleBuilding = selectedBuildings[0];
            onSiteVisitQuestions?.forEach((q) => {
                if (['Building', 'Roof', 'RoofSegment'].includes(q.targetEntity ?? '')) {
                    const defaultEntity =
                        q.targetEntity === 'Building'
                            ? singleBuilding.id
                            : q.targetEntity === 'Roof' && singleBuilding.roof
                              ? singleBuilding.roof.id
                              : q.targetEntity === 'RoofSegment' && singleBuilding.roof?.roofSegments.length === 1
                                ? singleBuilding.roof.roofSegments[0].id
                                : null;

                    if (defaultEntity) {
                        setQuestionEntityMap((prev) => ({
                            ...prev,
                            [q.id]: [defaultEntity],
                        }));
                        setErrors((prev) => ({ ...prev, [q.id]: false }));
                    }
                }
            });
        } else {
            setQuestionEntityMap({});
        }
    }, [selectedBuildings, onSiteVisitQuestions]);

    const handleSubmit = () => {
        let hasErrors = false;
        const newErrors: Record<string, boolean> = {};

        onSiteVisitQuestions?.forEach((q) => {
            if (
                ['Building', 'Roof', 'RoofSegment'].includes(q.targetEntity ?? '') &&
                (!questionEntityMap[q.id] || questionEntityMap[q.id].length === 0)
            ) {
                newErrors[q.id] = true;
                hasErrors = true;
            }
        });

        setErrors(newErrors);

        if (!hasErrors && onSiteVisitQuestions && id) {
            console.debug('Submitting Onsite Visit');

            const submittedQuestions: SubmittedQuestionDto[] = onSiteVisitQuestions
                .flatMap((q) => {
                    const entities = questionEntityMap[q.id] || [];
                    if (entities.length > 0) {
                        return entities.map((entityId) => ({
                            questionId: q.id,
                            questionTag: q.tag ?? null,
                            targetEntity: q.targetEntity,
                            targetId: entityId,
                        }));
                    } else {
                        return null;
                    }
                })
                .filter((q) => q !== null);
            console.debug('Submitted Questions', submittedQuestions);

            dispatch(submitOnSiteVisit({ externalId: id, questions: submittedQuestions }));
        }
    };

    if (!id) {
        return (
            <Grid container direction="row" xs={12} spacing={2}>
                {t('No Onsite Visit ID Provided')}
            </Grid>
        );
    }

    return (
        <Grid container spacing={2} className={styles.rootContainer}>
            <Grid xs={12}>
                <Typography level="h2" className={styles.sectionTitle}>
                    {t('Submit Onsite Visit')}
                </Typography>
            </Grid>

            <Grid xs={12}>
                <Typography level="h3">{t('Select Buildings')}</Typography>
                <Grid xs={12}>
                    <Search />
                </Grid>
                <Grid xs={12}>
                    <Map
                        onSelectBuildings={(buildings, addedBuilding) => handleBuildingSelect(buildings, addedBuilding)}
                        selectedBuildings={selectedBuildings}
                    />
                </Grid>
            </Grid>

            <Grid xs={12}>
                <Typography level="h3">{t('Visit Questions')}</Typography>
                <Grid
                    xs={12}
                    sx={{
                        maxHeight: 400,
                        overflowY: 'auto',
                        padding: 2,
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        backgroundColor: '#fff',
                    }}
                >
                    {onSiteVisitQuestions ? (
                        <Grid container spacing={3} direction="column">
                            {onSiteVisitQuestions.map((q) => (
                                <Grid xs={12} key={q.id}>
                                    <Card variant="outlined" className={styles.cardStyle}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <FormLabel>{q.question}</FormLabel>
                                            <Input defaultValue={q.response} fullWidth readOnly />
                                        </FormControl>
                                        {/* ✅ Display Attachments if available */}
                                        {q.attachments && q.attachments.length > 0 && (
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{ mt: 2 }}
                                                className={styles.attachmentGrid}
                                                direction="row"
                                            >
                                                {q.attachments.map((attachment, index) => (
                                                    <Grid key={index} xs="auto">
                                                        {attachment.metadata?.type === 'IMAGE' ? (
                                                            <img
                                                                src={attachment.url}
                                                                alt={attachment.name ?? undefined}
                                                                style={{
                                                                    maxWidth: '100px',
                                                                    maxHeight: '100px',
                                                                    marginRight: '10px',
                                                                }}
                                                            />
                                                        ) : (
                                                            <a
                                                                href={attachment.url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ textDecoration: 'none', color: '#007bff' }}
                                                            >
                                                                {attachment.name}
                                                            </a>
                                                        )}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        )}

                                        {['Building', 'Roof', 'RoofSegment'].includes(q.targetEntity ?? '') && (
                                            <FormControl sx={{ mt: 2, width: '100%' }}>
                                                <FormLabel>{t(`Choose ${q.targetEntity}(s)`)}</FormLabel>
                                                <Select
                                                    multiple
                                                    value={questionEntityMap[q.id] || []}
                                                    onChange={(event, newValues) => {
                                                        setQuestionEntityMap((prev) => ({
                                                            ...prev,
                                                            [q.id]: newValues as string[],
                                                        }));
                                                        setErrors((prev) => ({
                                                            ...prev,
                                                            [q.id]: newValues.length === 0,
                                                        }));
                                                    }}
                                                    placeholder={t(`Choose ${q.targetEntity}(s)`)}
                                                >
                                                    {selectedBuildings?.flatMap((building) => {
                                                        if (q.targetEntity === 'Building') {
                                                            return [
                                                                <Option
                                                                    key={building.id}
                                                                    value={building.id}
                                                                    onMouseEnter={() =>
                                                                        dispatch(setHighlightedBuilding(building.code))
                                                                    }
                                                                    onMouseLeave={() =>
                                                                        dispatch(setHighlightedBuilding(null))
                                                                    }
                                                                >
                                                                    {building.code} (Building)
                                                                </Option>,
                                                            ];
                                                        } else if (q.targetEntity === 'Roof' && building.roof) {
                                                            return [
                                                                <Option
                                                                    key={building.roof.id}
                                                                    value={building.roof.id}
                                                                    onMouseEnter={() =>
                                                                        dispatch(setHighlightedBuilding(building.code))
                                                                    }
                                                                    onMouseLeave={() =>
                                                                        dispatch(setHighlightedBuilding(null))
                                                                    }
                                                                >
                                                                    {building.roof.code} (Roof)
                                                                </Option>,
                                                            ];
                                                        } else if (
                                                            q.targetEntity === 'RoofSegment' &&
                                                            building.roof?.roofSegments
                                                        ) {
                                                            return building.roof.roofSegments.map((segment) => (
                                                                <Option
                                                                    key={segment.id}
                                                                    value={segment.id}
                                                                    onMouseEnter={() =>
                                                                        dispatch(setHighlightedBuilding(building.code))
                                                                    }
                                                                    onMouseLeave={() =>
                                                                        dispatch(setHighlightedBuilding(null))
                                                                    }
                                                                >
                                                                    {segment.area ? segment.area.toFixed(2) : 'N/a'} m²
                                                                </Option>
                                                            ));
                                                        }
                                                        return [];
                                                    })}
                                                </Select>
                                                {errors[q.id] && (
                                                    <Typography color="danger">
                                                        {t('This selection is required')}
                                                    </Typography>
                                                )}
                                            </FormControl>
                                        )}
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <CircularProgress />
                    )}
                </Grid>
            </Grid>

            <Grid xs={12} className={styles.submitButton}>
                <Button variant="solid" size="lg" onClick={handleSubmit} disabled={submissionStatus?.isLoading}>
                    {submissionStatus?.isLoading ? <CircularProgress size="sm" /> : t('Submit')}
                </Button>
            </Grid>

            {submissionStatus && submissionStatus.message && (
                <Snackbar open autoHideDuration={4000} onClose={() => dispatch(setSubmissionStatus(null))}>
                    <Alert variant="solid" color={submissionStatus.type === 'success' ? 'success' : 'danger'}>
                        {submissionStatus.message}
                    </Alert>
                </Snackbar>
            )}
        </Grid>
    );
}
