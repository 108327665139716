import { CreateFeasibilityStudy } from './feasibility-study.dto';

import * as yup from 'yup';

export const INITIAL_VALUES: CreateFeasibilityStudy = {
    project: {
        municipalityName: '',
        name: '',
        address: '',
        imageGoogleMapsScreenshot: '',
    },
    contact: {
        name: '',
        address: '',
        email: '',
        phone: '',
        jobTitle: '',
    },
    potentialAnalysisResult: {
        buildingType: '',
        consumptionKwhPerYear: 0,
        pvKwp: 0,
        batteryKwh: 0,
        productionKwhPerYear: 0,
        co2Savings: 0,
        initialInvestment: 0,
        balance30Years: 0,
        rateOfReturn: 0,
        amortizationYears: 0,
        selfConsumptionRate: 0,
    },
    onsite: {
        authorName: '',
        onsiteDate: new Date(),
        accessToUtilityRoom: null,
        accessToMeterCabinet: null,
        accessToAttic: null,
        accessToRoof: null,
    },
    roof: {
        roofType: '',
        roofCovering: '',
        numberOfSelectedRoofSegments: 0,
        imageDroneRoof: '',
    },
    imagesPossibleModuleAssignment: [],
    staticAssessment: null,
    cableRouting: {
        hasSuitableCableRouting: null,
        imagesCableRouting: [],
    },
    electricInstallation: {
        hasSuitableConnectionPoint: null,
        connectionPointType: null,
        imagesMeterCabinet: [],
        hasSuitableInstallationZone: null,
        installationRoom: '',
        imagesInstallationZoneComponents: [],
    },
    fireProtection: {
        hasSuitableFireProtectionCableRouting: null,
        imageAreasWithFireProtectionMeasures: '',
        hasFireProtectionInstallationZone: null,
    },
};

export const VALIDATION_SCHEMA = yup.object({
    project: yup.object({
        imageGoogleMapsScreenshot: yup.string().required('Please provide a Google Maps screenshot.'),
    }),
    roof: yup.object({
        imageDroneRoof: yup.string().required('Please provide a drone photo of the roof.'),
    }),
    imagesPossibleModuleAssignment: yup.array().min(1, 'Please provide the possible module assignment.'),
    cableRouting: yup.object({
        imagesCableRouting: yup.array().min(1, 'Please provide at least one photo of the cable routing.'),
    }),
    electricInstallation: yup.object({
        imagesMeterCabinet: yup.array().min(1, 'Please provide at least one photo of the connection point.'),
        imagesInstallationZoneComponents: yup
            .array()
            .min(1, 'Please provide at least one photo of the installation zone components.'),
    }),
});
