import { CreateFeasibilityStudy } from './feasibility-study.dto';
import { httpClient2 } from 'src/infrastructure/admi-client/http-client';

// In some cases, content-disposition is ignored by the browser and the filename is not set correctly.
function buildDefaultFilename(dto: CreateFeasibilityStudy) {
    return `${dto.project.municipalityName} - ${dto.project.name}.docx`;
}

export const admiFeasibilityStudyService = {
    async createFeasibilityStudy(dto: CreateFeasibilityStudy): Promise<[Blob, string]> {
        const response = await httpClient2.post<Blob>('/v1/feasibility-study', dto, { responseType: 'blob' });
        const contentDisposition = response.headers['content-disposition'];
        const filenameMatch = contentDisposition
            ? contentDisposition.match(/filename="([^"]+)"/)
            : buildDefaultFilename(dto);

        return [response.data, filenameMatch[1]];
    },
};
