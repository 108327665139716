import { FormikHelpers } from 'formik';

import { CreateFeasibilityStudy } from './feasibility-study.dto';
import { admiOnsiteService } from 'src/features/onsite/admi-onsite.service';
import { OnsiteDataPoint } from 'src/features/onsite/onsite.dto';
import { appendListInMap, ifNotNull } from 'src/common/helpers';

export async function loadOnsiteIntoFormik(onsiteId: string, formik: FormikHelpers<CreateFeasibilityStudy>) {
    const onsite = await admiOnsiteService.getOnsite(onsiteId);

    const formData: { [_: string]: OnsiteDataPoint } = {};
    onsite.data.forEach((item) => {
        formData[item.reference] = item;
    });

    const MAPPINGS = [
        ['municipality', 'project.municipality'],
        ['building-name', 'project.name'],
        ['address', 'project.address'],

        ['roof-type', 'roof.roofType'],
        ['roof-covering', 'roof.roofCovering'],

        ['cable-routing-dc-outside', 'cableRouting.imagesCableRouting'], // these two map to the same attribute
        ['cable-routing-dc-inside', 'cableRouting.imagesCableRouting'],
        ['cable-routing-suitable', 'cableRouting.hasSuitableCableRouting'],

        ['meter-cabinet', 'electricInstallation.imagesMeterCabinet'],
        ['installation-zone-suitable', 'electricInstallation.hasSuitableInstallationZone'],
        ['system-components', 'electricInstallation.installationRoom'],
        ['system-components-photo', 'electricInstallation.imagesInstallationZoneComponents'],

        // fire protection is always added manually
    ];

    const imageUrls = new Map<string, string[]>();

    for (const [onsiteRef, formikPath] of MAPPINGS) {
        ifNotNull(formData[onsiteRef], (value) => {
            if (value.photos.length > 0) {
                // Append instead of set to allow referencing the same attribute multiple times
                appendListInMap(
                    imageUrls,
                    formikPath,
                    value.photos.map((photo) => photo.url),
                );
            } else if (value.response != null) {
                formik.setFieldValue(formikPath, value.response);
            }
        });
    }

    // Mappings involving transformations
    // If it gets more, think about refactoring this to storing functors in the mapping table.
    // These business rules will be moved to the backend though, when we switch to the new API.
    const numRoofs = onsite.data.filter((item) => item.reference === 'roof-type').length;
    formik.setFieldValue('roof.numberOfSelectedRoofSegments', numRoofs);

    return imageUrls;
}
