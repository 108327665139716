import { useCallback, useEffect, useRef, useState } from 'react';
import useGoogleMapsApiLoader from 'src/infrastructure/google/useGoogleMapsApiLoader';
import { generateColorHashFromString } from 'src/common/generateColorHashFromString';
import { useAppDispatch, useAppSelector } from 'src/redux-app-hooks';
import useDebouncer from 'src/common/useDebouncer';
import { loadRealEstateData, setLocation } from '../onsite-visit.slice';
import { CircularProgress, Grid } from '@mui/joy';
import { feature, flatten } from '@turf/turf';
import { Building } from '../onsite-visit.dto';

interface MapProps {
    selectedBuildings: Building[] | null;
    onSelectBuildings: (buildings: Building[], addedBuilding: Building | null) => void;
}

const LOAD_BUILDINGS_DEBOUNCE_MS = 2000;

export default function Map({ selectedBuildings, onSelectBuildings }: MapProps) {
    const dispatch = useAppDispatch();
    const loader = useGoogleMapsApiLoader();

    const { location, buildings, isMapLoading, highlightedBuilding } = useAppSelector((state) => state.onsiteVisit);
    const locationRef = useRef(location);

    const [map, setMap] = useState<google.maps.Map | undefined>();
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();
    const [polygons, setPolygons] = useState<{
        [key: string]: google.maps.Polygon[];
    }>({});
    const [localSelectedBuildings, setLocalSelectedBuildings] = useState<Building[]>(selectedBuildings || []);
    const [roofSegmentMarkers, setRoofSegmentMarkers] = useState<google.maps.marker.AdvancedMarkerElement[]>([]);
    const loadBuildingsDebouncer = useDebouncer();

    useEffect(() => {
        if (selectedBuildings) {
            setLocalSelectedBuildings(selectedBuildings);
        }
    }, [selectedBuildings]);

    useEffect(() => {
        if (!map) {
            loadMap();
        }
    }, []);

    useEffect(() => {
        if (map !== undefined && geocoder !== undefined) {
            google.maps.event.addListener(map, 'dragend', () => dragend(map, geocoder));
        }
    }, [map, geocoder]);

    async function dragend(map: google.maps.Map, geocoder: google.maps.Geocoder): Promise<void> {
        console.debug('dragend', map, geocoder);
        if (map !== undefined && geocoder !== undefined) {
            try {
                const center = map.getCenter();
                if (!center) {
                    throw new Error('Map center is not defined');
                }

                const geocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode({
                    location: center,
                });
                console.debug('geocoderResponse', geocoderResponse);

                dispatch(
                    setLocation({
                        address: geocoderResponse.results[0].formatted_address,
                        lat: center.lat(),
                        lng: center.lng(),
                    }),
                );
            } catch (e) {
                console.error('Geocoder failed due to: ' + e);
            }
        }
    }

    useEffect(() => {
        locationRef.current = location;

        if (!location) {
            return;
        }

        loadBuildingsDebouncer(() => {
            console.debug('RealEstateData.location', location);
            dispatch(
                loadRealEstateData({
                    location,
                    radius: 1000,
                    layers: [],
                }),
            );
        }, LOAD_BUILDINGS_DEBOUNCE_MS);
    }, [location]);

    useEffect(() => {
        refreshCenter();
        refreshPolygons();
        refreshRoofSegementMarkers;
        refreshSearchMarker();
    }, [buildings, location]);

    useEffect(() => {
        refreshPolygons();
        refreshRoofSegementMarkers();
    }, [localSelectedBuildings, buildings, highlightedBuilding]);

    function refreshCenter() {
        console.debug('refreshCenter', location);

        if (map !== undefined && location) {
            const centerLatLng = new google.maps.LatLng(location.lat, location.lng);
            map.setCenter(centerLatLng);
        }
    }

    async function refreshSearchMarker(): Promise<void> {
        console.debug('refreshSearchMarker', location);

        if (map !== undefined && location) {
            const { AdvancedMarkerElement } = (await loader.importLibrary('marker')) as google.maps.MarkerLibrary;
            if (marker !== undefined) {
                marker.map = null;
                setMarker(marker);
            }

            setMarker(
                new AdvancedMarkerElement({
                    position: { lat: location.lat, lng: location.lng },
                    map,
                    title: location.address + '\n' + location.lat + ',' + location.lng,
                }),
            );
        }
    }

    async function loadMap() {
        console.debug('loadMap');

        const mapOptions: google.maps.MapOptions = {
            mapId: 'admi-tagger',
            mapTypeId: 'satellite',
            mapTypeControl: false,
            tilt: 0,
            tiltInteractionEnabled: false,
            streetViewControl: false,
            fullscreenControl: false,
        };
        const mapElement = document.getElementById('map');
        if (mapElement !== null) {
            const { Map } = (await loader.importLibrary('maps')) as google.maps.MapsLibrary;

            // Since this is an async function, the location property might be a stale value
            // We therefore use a ref to access the latest version.
            if (locationRef.current) {
                console.debug('Setting map center to', locationRef.current);
                mapOptions.center = { lat: locationRef.current.lat, lng: locationRef.current.lng };
                mapOptions.zoom = 18;
            }

            setMap(new Map(mapElement, mapOptions));

            const { Geocoder } = (await loader.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
            setGeocoder(new Geocoder());
        }
    }

    async function refreshPolygons() {
        console.debug('refreshPolygons');
        if (!map || !buildings) return;

        // Clear existing polygons
        Object.values(polygons).forEach((polygons) => polygons.forEach((polygon) => polygon.setMap(null)));
        setPolygons({});

        // Initialize New Polygon Map
        const selectedColor = 'f5cb5a';

        // Iterate over all buildings
        buildings.forEach((building) => {
            const isSelected = localSelectedBuildings.some((b) => b.code === building.code);
            const isHighlighted = highlightedBuilding === building.code;
            const unselectedColor = generateColorHashFromString(building.code);

            // Determine the color: selected buildings use selectedColor, others get unique colors
            const color = isSelected ? selectedColor : unselectedColor;

            const buildingsPolygons = flatten(feature(building.geometry));
            buildingsPolygons.features.forEach((feature) => {
                // Extract polygon paths from the feature's coordinates
                const polygonPaths = feature.geometry.coordinates.map((ring: [number, number][]) =>
                    ring.map(([lng, lat]) => ({ lat, lng })),
                );
                // Create a new polygon with the determined color
                const polygon = new google.maps.Polygon({
                    paths: polygonPaths,
                    strokeColor: `#${color}`,
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    fillColor: `#${color}`,
                    fillOpacity: isHighlighted ? 1 : 0.5,
                });

                // Add Click Event to Toggle Selection
                google.maps.event.addListener(polygon, 'click', () => toggleBuildingSelection(building));

                // Display the polygon on the map
                polygon.setMap(map);

                // Update state with the new polygon
                const statePolygons = polygons;
                if (!statePolygons[building.code]) {
                    statePolygons[building.code] = [];
                }
                statePolygons[building.code].push(polygon);

                setPolygons(statePolygons);
            });
        });
    }

    async function refreshRoofSegementMarkers() {
        if (!map) {
            return;
        }

        const { AdvancedMarkerElement } = (await loader.importLibrary('marker')) as google.maps.MarkerLibrary;
        roofSegmentMarkers.forEach((marker) => (marker.map = null));

        const newRoofSegmentMarkers: google.maps.marker.AdvancedMarkerElement[] = [];

        selectedBuildings?.forEach((building, buildingIndex) => {
            building.roof?.roofSegments.forEach((roofSegment, roofSegmentIndex) => {
                console.debug('roofSegment', roofSegment, buildingIndex, roofSegmentIndex);
                const position = { lng: roofSegment.center[0], lat: roofSegment.center[1] };
                const uniqueRoofSegmentId = roofSegment.id;

                const dot = document.createElement('div');
                dot.style.width = '55px';
                dot.style.height = '15px';
                dot.style.backgroundColor = '#eda49f';
                dot.style.border = '1px solid black';
                dot.style.fontWeight = '700';
                dot.style.color = '#fff';
                dot.style.textAlign = 'center';

                // dot.style.borderRadius = '50%';
                dot.textContent = `${roofSegment.area ? roofSegment.area.toFixed(2) : 'N/a'} m² (${uniqueRoofSegmentId})`;

                const marker = new AdvancedMarkerElement({
                    position,
                    map,
                    content: dot,
                    gmpClickable: true,
                });
                newRoofSegmentMarkers.push(marker);
            });
        });

        setRoofSegmentMarkers(newRoofSegmentMarkers);
    }

    const toggleBuildingSelection = useCallback(
        (building: Building) => {
            setLocalSelectedBuildings((prevSelectedBuildings) => {
                const isAlreadySelected = prevSelectedBuildings.some((b) => b.code === building.code);
                const updatedSelectedBuildings = isAlreadySelected
                    ? prevSelectedBuildings.filter((b) => b.code !== building.code)
                    : [...prevSelectedBuildings, building];

                const addedBuilding = isAlreadySelected ? null : building;

                if (JSON.stringify(prevSelectedBuildings) !== JSON.stringify(updatedSelectedBuildings)) {
                    console.info('Selected Building:', addedBuilding?.code);
                    onSelectBuildings(updatedSelectedBuildings, addedBuilding);
                }

                return updatedSelectedBuildings;
            });
        },
        [onSelectBuildings],
    );

    return (
        <Grid xs={12} container spacing={2}>
            <Grid style={{ height: '500px', width: '100%', position: 'relative' }}>
                <div id="map" style={{ height: '100%', width: '100%' }} />
                {isMapLoading && (
                    <div style={{ position: 'absolute', top: 8, left: 8 }}>
                        <CircularProgress />
                    </div>
                )}
            </Grid>
        </Grid>
    );
}
