import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router';
import { THEME_ID as MATERIAL_THEME_ID, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { getRoutes } from 'src/app/AppRoutes';

const materialTheme = createTheme({
    cssVariables: true,
});

export default function App() {
    return (
        <ThemeProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
            <JoyCssVarsProvider>
                <CssBaseline enableColorScheme />
                <RouterProvider router={createBrowserRouter(getRoutes())} />
            </JoyCssVarsProvider>
        </ThemeProvider>
    );
}
