import { resizeImage } from 'src/common/resizeImage';
import React, { Fragment, useEffect, useState } from 'react';

interface Properties {
    imageUrls?: string[];
    name: string;
    formik: any;
    multiple?: boolean;
    required?: boolean;
}

export default function ImageSelector(props: Properties) {
    const { imageUrls, name, formik, multiple = false, required = false } = props;

    /**
     * Images that are related to the field, and they can be selected
     */
    const [images, setImages] = useState<string[]>([]);
    /**
     * Images that are selected to be uploaded
     */
    const [selectedImages, setSelectedImages] = useState<string[]>([]);

    // this hook will download all the remote images that are provided at the component level
    useEffect(() => {
        downloadImages();
    }, [imageUrls]);

    // when the images are loaded, we select the first image as the default selected image
    useEffect(() => {
        if (images.length > 0 && required) {
            setSelectedImages([images[0]]);
        }
    }, [images]);

    // when an image is selected, we set the the image url to the formik field
    //  in order to upload on form submit
    useEffect(() => {
        if (selectedImages.length > 0) {
            if (multiple) {
                selectedImages.forEach((url: string, index) => formik.setFieldValue(`${name}[${index}]`, url));
            } else {
                formik.setFieldValue(name, selectedImages[0]);
            }
        }
    }, [selectedImages]);

    /**
     * Download all the images that are provided at the start of the component
     *  and set them to the local images state
     */
    async function downloadImages() {
        if (imageUrls) {
            const newImages = await Promise.all(
                imageUrls.map(async (url) => {
                    const response = await fetch(url);
                    const data = await response.blob();
                    const file = new File([data], url.substring(url.lastIndexOf('/') + 1), {
                        type: data.type || 'image/png',
                    });

                    return loadFile(file);
                }),
            );

            setImages(newImages);
        }
    }

    /**
     * Handle the image click event
     *  if multiple is set to true, we will allow multiple image selection
     *  otherwise, we will select only one image
     *
     * @param image the image url that is clicked
     */
    async function handleImageClick(image: string) {
        if ((selectedImages as any).includes(image)) {
            setSelectedImages((selectedImages as any).filter((url: string) => url !== image));
        } else {
            if (multiple) {
                setSelectedImages([...selectedImages, image]);
            } else {
                setSelectedImages([image]);
                formik.setFieldValue(name, image);
            }
        }
    }

    /**
     * Handle the file selection event
     *  when a file is selected, we will load the file and resize it
     *  then we will set the image url to the local images state
     *
     * @param event the file selection event
     */
    async function handleFileSelected(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();

        const files = event.target.files;
        if (files) {
            const newImages = await Promise.all(Array.from(files).map(loadFile));
            setImages([...images, ...newImages]);

            event.target.value = '';
        }
    }

    /**
     * Load the file and resize it
     *
     * @param file the file to be loaded
     */
    function loadFile(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = async () => {
                if (fileReader.readyState === fileReader.DONE) {
                    const resizedImageDataUrl = await resizeImage(fileReader.result as string, 570);
                    URL.revokeObjectURL(fileReader.result as string);

                    resolve(resizedImageDataUrl);
                }
            };
            fileReader.onerror = reject;

            fileReader.readAsDataURL(file);
        });
    }

    return (
        <Fragment>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px', marginBottom: '10px' }}>
                {images.map((url: string, index: number) => (
                    <div
                        key={index}
                        style={{
                            border: (selectedImages as any).includes(url) ? '4px solid blue' : '4px solid transparent',
                            cursor: 'pointer',
                            position: 'relative',
                            height: '208px',
                            width: '308px',
                        }}
                        onClick={() => handleImageClick(url)}
                    >
                        <img
                            src={url}
                            alt={`img-${index}`}
                            style={{
                                maxWidth: '300px',
                                maxHeight: '200px',
                                top: '50%',
                                margin: '0 auto',
                                msTransform: 'translateY(-50%)',
                                transform: 'translateY(-50%)',
                                position: 'absolute',
                            }}
                        />
                    </div>
                ))}
            </div>

            <input type="file" multiple onChange={handleFileSelected} />
        </Fragment>
    );
}
