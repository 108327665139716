import { useState } from 'react';
import { Autocomplete, AutocompleteOption, CircularProgress } from '@mui/joy';
import Search from '@mui/icons-material/Search';

import useDebouncer from 'src/common/useDebouncer';
import { Contact } from '../crm.dto';
import { admiContactService } from '../admi-contact.service';

export function ContactAutocomplete({ onSelectContact }: { onSelectContact: (contact: Contact) => void }) {
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const debouncer = useDebouncer();

    function handleInputChange(event: any, value: string | null) {
        if (!value) {
            return;
        }

        setLoading(true);

        debouncer(async () => {
            const contacts = await admiContactService.searchContacts(value);
            setLoading(false);
            setContacts(contacts);
        }, 1000);
    }

    function handleChange(event: any, value: Contact | null) {
        if (value) {
            onSelectContact(value);
        }
    }

    return (
        <>
            <Autocomplete
                options={contacts}
                loading={loading}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                onInputChange={handleInputChange}
                onChange={handleChange}
                filterOptions={(x) => x} // this is required for the options to update in async mode
                startDecorator={<Search />}
                endDecorator={loading ? <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} /> : null}
                renderOption={(props, option) => (
                    <AutocompleteOption {...props} key={option.name}>
                        <strong>{option.name}</strong>
                        <span>
                            {option.streetAddress}, {option.zipCodeAndCity}
                        </span>
                    </AutocompleteOption>
                )}
            />
        </>
    );
}
