import { Alert, Stack, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from '@mui/icons-material';

import { setMaxInstalationCapacity } from '../pv-analysis.slice';
import useDebouncer from 'src/common/useDebouncer';
import { useAppDispatch, useAppSelector } from 'src/redux-app-hooks';

function PvSizeSlider() {
    const selectorMaxSize = useAppSelector((state) => state.pvAnalysis.editedVariant.maxSystemCapacityAdjustment);
    const dispatch = useAppDispatch();

    // We need a local state, because the Redux state is debounced
    const [maxSize, setMaxSize] = useState(selectorMaxSize);
    useEffect(() => {
        setMaxSize(selectorMaxSize);
    }, [selectorMaxSize]);

    const { t } = useTranslation();

    const debouncer = useDebouncer();

    function handleSizeChange(value: number) {
        setMaxSize(value);
        debouncer(() => dispatch(setMaxInstalationCapacity(value)), 1000);
    }

    return (
        <Stack direction="column">
            <Alert color="info" icon={<Info />}>
                {t(
                    'Disclaimer: If you are creating a potential analysis for a balancing group system, a second system or a roof with an existing PV system, it is probably sensible to create your own roof plan (full occupancy) and then use the slider for the roof area. It is best to compare the available roof area with a measurement of the area in TIM or Google Maps etc.',
                )}
            </Alert>
            <Slider
                value={maxSize}
                max={100}
                min={10}
                valueLabelDisplay="auto"
                onChange={(_event, value) => handleSizeChange(value as number)}
            />
        </Stack>
    );
}

export default PvSizeSlider;
