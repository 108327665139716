import { combineReducers, configureStore } from '@reduxjs/toolkit';

import featureFlags from './features/feature-flags/feature-flags.slice';
import notifications from './features/notifications/notifications.slice';
import { tagging } from './features/tagging/tagging.reducer';
import { onsite } from './features/onsite/onsite.reducer';
import pvAnalysis from './features/pv-analysis/pv-analysis.slice';
import onsiteVisit from './features/onsite-visit/onsite-visit.slice';

const reducer = combineReducers({
    featureFlags,
    notifications,
    tagging,
    onsite,
    pvAnalysis,
    onsiteVisit,
});

const store = configureStore({
    reducer,
});

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export default store;
