import React, { useEffect } from 'react';
import { Sheet, IconButton, Typography, Input, FormControl, Box } from '@mui/joy';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { parse } from 'csv-parse/browser/esm/sync';

import { CustomLoadProfileDto, LoadProfileType } from '../pv-calculator.dto';
import { useAppDispatch } from 'src/redux-app-hooks';
import { NotificationSeverity, showSnackbar } from 'src/features/notifications/notifications.slice';

export default function CustomLoad(props: {
    value: CustomLoadProfileDto;
    onChange: (loadProfile: CustomLoadProfileDto) => void;
    onDelete: () => void;
}) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [chartData, setChartData] = React.useState<any>(null);

    function updateChart(profileValues: number[]) {
        // Combine every 24 values into one day
        const dailyValues: number[] = [];
        for (let i = 0; i < profileValues.length; i += 24) {
            dailyValues.push(profileValues.slice(i, i + 24).reduce((a, b) => a + b));
        }

        setChartData({
            labels: dailyValues.map((_, i) => i + 1),
            datasets: [
                {
                    label: 'Load',
                    data: dailyValues,
                    backgroundColor: 'rgba(100, 150, 90, 0.7)',
                },
            ],
        });
    }

    useEffect(() => {
        if (props.value.values) {
            updateChart(props.value.values);
        }
    }, []);

    async function onChange(file: File) {
        try {
            const data = parse(await file.text(), { from: 2, cast: (value) => parseFloat(value) });
            const profileValues = data.map((row: any) => row[0]);

            updateChart(profileValues);
            props.onChange({
                type: LoadProfileType.CUSTOM,
                values: profileValues || [],
            });
        } catch (e) {
            dispatch(
                showSnackbar({
                    message: t('Could not parse CSV file. Please check the contents.'),
                    severity: NotificationSeverity.Error,
                }),
            );
        }
    }

    return (
        <Sheet variant="outlined" sx={{ p: 2 }}>
            <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => props.onDelete()}>
                <Delete />
            </IconButton>
            <Typography level="h4">{t('Custom (from CSV)')}</Typography>

            <Typography>
                {t(
                    'The load profile is derived from the first column of the CSV which is expected to have hourly data for each day of the year (8760 rows + header)',
                )}
            </Typography>

            <FormControl sx={{ mt: 1 }}>
                <Input
                    type="file"
                    slotProps={{ input: { accept: '.csv' } }}
                    onChange={(e) => e.target?.files && onChange(e.target.files[0])}
                />
            </FormControl>

            {chartData && (
                <Box sx={{ height: '25rem' }}>
                    <Bar data={chartData} options={{ maintainAspectRatio: false }} />
                </Box>
            )}
        </Sheet>
    );
}
