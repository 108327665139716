import React from 'react';
import { formatToGermanNumber } from 'src/common/toGermanNumber';

const LOAD_COMPONENT_FRIENDLY_NAMES = new Map([
    // These are not translated, because they're part of the sales print
    // which is always in German
    ['base', 'Grundverbrauch'],
    ['custom', 'Zusätzlicher Verbraucher'],
    ['heat_pump', 'Wärmepumpe'],
]);

export function getComponentFriendlyName(components: [string, unknown][], index: number, baseUsageEstimated: boolean) {
    const name = components[index][0];

    let friendlyName = LOAD_COMPONENT_FRIENDLY_NAMES.get(name);
    if (!friendlyName) {
        throw new Error(`unknown energy component name: ${name}`);
    }

    // Use first custom component as base if no base component exists
    if (name === 'custom' && components.findIndex(([name]) => name === 'base') === -1) {
        const isFirstCustom = components.findIndex(([name]) => name === 'custom') === index;
        if (isFirstCustom) {
            friendlyName = LOAD_COMPONENT_FRIENDLY_NAMES.get('base');
        }
    }

    const estimated = (name !== 'base' || baseUsageEstimated) && name !== 'custom';
    return `${friendlyName} (${estimated ? 'geschätzt' : 'gemessen'})`;
}

export default function EnergyConsuptionComponents({
    components,
    baseUsageEstimated,
}: {
    components: [string, number][];
    baseUsageEstimated: boolean;
}) {
    return (
        <div className="estimatedEnergyUse">
            <strong>Stromverbrauch:</strong>
            <br />
            {components.map(([_, usage], index) => (
                <React.Fragment key={index}>
                    <span>{`${getComponentFriendlyName(components, index, baseUsageEstimated)}: ${formatToGermanNumber(
                        usage,
                        0,
                    )} kWh`}</span>
                    <br />
                </React.Fragment>
            ))}
        </div>
    );
}
