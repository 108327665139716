import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';

import { Outlet } from 'react-router';

import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';

import AppBreadcrumb from 'src/app/AppBreadcrumb';

import Sidebar from '../app/Sidebar';
import Header from '../app/Header';
import NotificationSnackbar from 'src/features/notifications/NotificationSnackbar';

export default function AppLayout() {
    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                <Header />
                <Sidebar />
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: { xs: 2, md: 6 },
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: { xs: 2, sm: 2, md: 3 },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: '100dvh',
                        gap: 1,
                        overflow: 'auto',
                    }}
                >
                    <AppBreadcrumb />
                    <Outlet />
                </Box>
            </Box>

            <NotificationSnackbar />
        </CssVarsProvider>
    );
}
