import { clone, coordEach } from '@turf/turf';
import { Geometry } from 'geojson';
import proj4 from 'proj4';

proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs');

/**
 * Converts a GeoJSON geometry from EPSG:4326/WGS84 to EPSG:25832
 * @param geometry GeoJSON geometry that is interpreted as EPSG:4326
 * @returns A geometry of the same type with converted coordinates
 *
 * @note WGS84 is a geodetic coordinate system. This means it cannot be used directly for calculations on distances along all 3 axes.
 * Instead, it is required to convert it to a projected coordinate system that is precise enough in the given area. For Germany,
 * the UTM zone 32 (EPSG:25832) is a good choice. EPSG 25832 also uses meters as units for all axes.
 *
 */
export function epsg4326ToEpsg25832<GeometryType extends Geometry>(geometry: GeometryType): GeometryType {
    const convertedGeometry = clone(geometry);

    coordEach(convertedGeometry, (coord) => {
        const converted = proj4('EPSG:4326', 'EPSG:25832', coord);
        coord[0] = converted[0];
        coord[1] = converted[1];
    });

    return convertedGeometry;
}

export function convertEpsg25832toEpsg4326<GeometryType extends Geometry>(geometry: GeometryType): GeometryType {
    const convertedGeometry = clone(geometry);

    coordEach(convertedGeometry, (coord) => {
        const converted = proj4('EPSG:25832', 'EPSG:4326', coord);
        coord[0] = converted[0];
        coord[1] = converted[1];
    });

    return convertedGeometry;
}

/**
 * Identifies if the coordinates are in EPSG:25832 or EPSG:4326
 * @param coords array of coordinates [longitude, latitude] or [easting, northing]
 * @returns 'EPSG:4326' | 'EPSG:25832' | 'unknown'
 */
export function identifyCoordinateSystem(coords: [number, number]): 'EPSG:4326' | 'EPSG:25832' | 'unknown' {
    const [longitude, latitude] = coords;

    if (latitude >= -90 && latitude <= 90 && longitude >= -180 && longitude <= 180) {
        return 'EPSG:4326';
    } else {
        return 'EPSG:25832';
    }
}
