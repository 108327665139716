import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Skeleton,
    Box,
    TextField,
} from '@mui/material';
import { ExpandMore, SolarPower, Preview, Handyman, Refresh, Troubleshoot, Save } from '@mui/icons-material';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import classNames from 'classnames';

import { formFieldBuilder } from 'src/common/formikUtils';
import { formValidationSchema, INITIAL_FORM_VALUES } from './formDefinitions';
import PVInput, { OptimizerMode, PVInputParams } from 'src/features/pv-calculator/components/PVInput';
import { Optimizer } from 'src/features/pv-calculator/pv-calculator.dto';
import SalesResult, { SALES_RESULT_WIDTH, SALES_RESULT_HEIGHT } from 'src/features/sales/SalesResult';
import SalesPrint from 'src/features/sales/SalesPrint';
import EnergyConsumptionComponents from 'src/features/sales/components/EnergyConsumptionComponents';
import Search from './components/Search';
import Map, { TakeScreenshotOptions } from './components/Map';
import styles from './PvAnalysisCreateEditPage.module.css';
import { admiPVAnalysisService } from './admi-pv-analysis.service';
import { Business, MapOutlined, Tune } from '@mui/icons-material';
import SelectedBuildings from './components/SelectedBuildings';
import PvSizeSlider from './components/PvSizeSlider';
import { useAppDispatch, useAppSelector } from 'src/redux-app-hooks';
import { NotificationSeverity, showSnackbar } from 'src/features/notifications/notifications.slice';
import { calculatePvResults, loadPvAnalysisVariant, resetState } from './pv-analysis.slice';
import PVOptimizationInsights from 'src/features/pv-calculator/components/PVOptimizationInsights';
import { useNavigateBlocker } from 'src/common/navigateBlocker';

export default function PvAnalysisCreateEditPage() {
    const { editedVariant, errorMessage, isCalculationLoading } = useAppSelector((state) => state.pvAnalysis);
    const { pvAnalysisUuid, pvAnalysisName, name, calculatorResults, buildings: selectedBuildings } = editedVariant;
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();
    let hasUnsavedChanges = true;
    useNavigateBlocker(t('Are you sure you want to leave this page? Changes will be lost.'), () => hasUnsavedChanges);

    const [screenshotOptions, setScreenshotOptions] = useState<TakeScreenshotOptions | null>(null);
    const [screenshotDataUrl, setScreenshotDataUrl] = useState<string | null>(null);
    const [pvParams, setPvParams] = useState<PVInputParams>(editedVariant.pvParams);
    const [calculatorResultsDirty, setCalculatorResultsDirty] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: INITIAL_FORM_VALUES,
        validationSchema: formValidationSchema,
        onSubmit: createPvAnalysis,
    });

    const formField = (name: string) => formFieldBuilder(formik, name);

    useEffect(() => {
        if (screenshotDataUrl) {
            URL.revokeObjectURL(screenshotDataUrl);
        }
        if (screenshotOptions) {
            setScreenshotDataUrl(URL.createObjectURL(screenshotOptions.blob));
        }
    }, [screenshotOptions]);

    // when the building selection changes, we need to revoke the screenshot data URL
    // because it's no longer valid for the new buildings
    useEffect(() => {
        if (screenshotDataUrl) {
            URL.revokeObjectURL(screenshotDataUrl);
        }
        setScreenshotDataUrl(null);
    }, [selectedBuildings]);

    useEffect(() => {
        if (errorMessage) {
            dispatch(showSnackbar({ message: errorMessage, severity: NotificationSeverity.Error }));
        }
    }, [errorMessage, dispatch]);

    // Initiate loading an existing PV analysis variant, if an ID is provided
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            dispatch(loadPvAnalysisVariant(id))
                .unwrap()
                .then((variant) => {
                    admiPVAnalysisService
                        .getPVAnalysisMapScreenshotBlob(variant.uuid)
                        .then((blob) => {
                            if (variant.mapScreenshot) {
                                setScreenshotOptions({
                                    center: variant.mapScreenshot.center,
                                    zoom: variant.mapScreenshot.zoom,
                                    blob,
                                });
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                showSnackbar({
                                    message: t('Map screenshot not available'),
                                    severity: NotificationSeverity.Error,
                                }),
                            );
                        });
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(
                        showSnackbar({
                            message: t(`Failed to load PV Analysis variant: ${JSON.stringify(error)}`),
                            severity: NotificationSeverity.Error,
                        }),
                    );
                })
                .finally(() => {
                    setCalculatorResultsDirty(false);
                });
        } else {
            dispatch(resetState());
        }
    }, [id]);

    useEffect(() => {
        formik.setValues({ pvAnalysisName, name });
    }, [pvAnalysisName, name]);

    useEffect(() => {
        setPvParams(editedVariant.pvParams);
    }, [editedVariant.pvParams]);

    useEffect(() => {
        setCalculatorResultsDirty(true);
    }, [selectedBuildings]);

    function calculatePv() {
        if (selectedBuildings.length > 0) {
            setCalculatorResultsDirty(false);
            dispatch(calculatePvResults({ pvParams, selectedBuildings }));
        } else {
            dispatch(
                showSnackbar({
                    message: t('Please select at least one building.'),
                    severity: NotificationSeverity.Warning,
                }),
            );
        }
    }

    async function createPvAnalysis(values: typeof INITIAL_FORM_VALUES) {
        console.debug('createPvAnalysis', values);

        try {
            if (!screenshotOptions) {
                dispatch(
                    showSnackbar({
                        message: 'Please take a screenshot of the map first.',
                        severity: NotificationSeverity.Warning,
                    }),
                );
                return;
            }

            if (!calculatorResults) {
                dispatch(
                    showSnackbar({
                        message: 'Please run the PV calculation first.',
                        severity: NotificationSeverity.Warning,
                    }),
                );
                return;
            }

            await admiPVAnalysisService.createPVAnalysisVariant(
                values.name,
                editedVariant.maxSystemCapacityAdjustment,
                selectedBuildings,
                { center: screenshotOptions.center, zoom: screenshotOptions.zoom },
                screenshotOptions.blob,
                {
                    optimizer: pvParams.optimizer,
                    buildingType: pvParams.buildingType,
                    fundingRate: pvParams.fundingRate,
                    energyPrice: pvParams.energyPrice,
                    loadProfiles: pvParams.loadProfiles,
                    targetProduction:
                        pvParams.productionOptimizerMode === OptimizerMode.Manual ? pvParams.targetProduction : null,
                    batteryCapacityFactor:
                        pvParams.batteryOptimizerMode === OptimizerMode.Manual ? pvParams.batteryKwpFactor : null,
                    limitTo100Kwp: pvParams.constrainFullRoofTo100KWp,
                },
                admiPVAnalysisService.mapPvCalculatorDtoToAppDto(calculatorResults.optimizedResult),
                admiPVAnalysisService.mapPvCalculatorDtoToAppDto(calculatorResults.fullRoofResult),
                editedVariant.pvAnalysisUuid,
                values.pvAnalysisName,
            );

            dispatch(
                showSnackbar({
                    message: t('PV Analysis created successfully.'),
                    severity: NotificationSeverity.Success,
                }),
            );

            hasUnsavedChanges = false;
            navigate('/pv-analysis');
        } catch (error) {
            console.error(error);
            if (error instanceof AxiosError) {
                if (error.message[0]) {
                    dispatch(showSnackbar({ message: error.message[0], severity: NotificationSeverity.Error }));
                }
            }
        }
    }

    // Scroll to the PV input section when clicking on a data point in the optimization insights
    // to review the changes.
    const pvInputSectionAnchorRef = useRef<HTMLElement>(null);
    function handleClickResultGraphDatapoint(targetProduction: number, batteryCapacityFactor: number) {
        setPvParams({
            ...pvParams,
            optimizer: Optimizer.ROI,
            productionOptimizerMode: OptimizerMode.Manual,
            targetProduction,
            batteryOptimizerMode: OptimizerMode.Manual,
            batteryKwpFactor: batteryCapacityFactor,
        });
        setCalculatorResultsDirty(true);

        pvInputSectionAnchorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <form>
            <Grid container direction="row" spacing={5}>
                <Grid size={12}>
                    <Typography variant="h4">{t('New Potential Analysis')}</Typography>
                </Grid>

                <Grid size={12}>
                    <div>
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6">
                                    <Handyman /> {t('General Information')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    fullWidth
                                    required
                                    label={t('PV Analysis Name')}
                                    disabled={!!pvAnalysisUuid}
                                    size="small"
                                    {...formField('pvAnalysisName')}
                                />

                                <TextField
                                    fullWidth
                                    required
                                    sx={{ mt: 2 }}
                                    label={t('Variation Name')}
                                    size="small"
                                    {...formField('name')}
                                />

                                {editedVariant.author && (
                                    <>
                                        <Typography sx={{ mt: 2 }}>{t('Author')}</Typography>
                                        <Typography variant="body2">{editedVariant.author}</Typography>
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6">
                                    <MapOutlined /> {t('Search')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid size={12}>
                                    <Search />
                                </Grid>
                                <Grid size={12}>
                                    <Map takeScreenshot={setScreenshotOptions} />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6">
                                    <Tune /> {t('Max. PV System installation capacity')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid size={12}>
                                    <PvSizeSlider />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6">
                                    <Business /> {t('Buildings')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid size={12}>
                                    <SelectedBuildings />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6">
                                    <SolarPower /> {t('PV Calculator')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <span ref={pvInputSectionAnchorRef}></span>
                                <PVInput
                                    params={pvParams}
                                    onChange={(value) => {
                                        setPvParams(value);
                                        setCalculatorResultsDirty(true);
                                    }}
                                />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6">
                                    <Preview /> {t('PV Analysis Result')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{ mb: 1 }}>
                                    <Button
                                        variant="contained"
                                        onClick={calculatePv}
                                        startIcon={<Refresh />}
                                        disabled={!calculatorResultsDirty}
                                    >
                                        {t('(Re-)calculate')}
                                    </Button>
                                </Box>

                                {isCalculationLoading && (
                                    <Skeleton
                                        variant="rectangular"
                                        width={SALES_RESULT_WIDTH}
                                        height={SALES_RESULT_HEIGHT}
                                    />
                                )}

                                {!isCalculationLoading && (
                                    <>
                                        {calculatorResults?.optimizedResult && calculatorResults?.fullRoofResult && (
                                            <Box
                                                className={classNames({
                                                    [styles.result]: true,
                                                    [styles.resultDirty]: calculatorResultsDirty,
                                                })}
                                            >
                                                <SalesPrint
                                                    projectName={formik.values.pvAnalysisName}
                                                    energyConsumption={
                                                        <EnergyConsumptionComponents
                                                            components={calculatorResults.optimizedResult.load_summary}
                                                            baseUsageEstimated={calculatorResults.baseUsageEstimated}
                                                        />
                                                    }
                                                    plot={
                                                        <>
                                                            {screenshotDataUrl && <img src={screenshotDataUrl} />}
                                                            {!screenshotDataUrl && (
                                                                <Typography variant="body2">
                                                                    {t('Please take a screenshot of the map first.')}
                                                                </Typography>
                                                            )}
                                                        </>
                                                    }
                                                >
                                                    <SalesResult
                                                        result={calculatorResults.optimizedResult}
                                                        fundingRate={calculatorResults.fundingRate}
                                                        optimizer={calculatorResults.optimizer}
                                                    />
                                                    <SalesResult
                                                        result={calculatorResults.fullRoofResult}
                                                        fundingRate={0}
                                                        optimizer={Optimizer.FULL_ROOF}
                                                    />
                                                </SalesPrint>
                                            </Box>
                                        )}

                                        {!calculatorResults && (
                                            <Typography>{t('Please run the PV calculation first.')}</Typography>
                                        )}
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6">
                                    <Troubleshoot /> {t('Optimization Insights')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {calculatorResults && (
                                    <PVOptimizationInsights
                                        result={calculatorResults.optimizedResult}
                                        onClickResultGraphDataPoint={handleClickResultGraphDatapoint}
                                    />
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>

                <Grid size={12}>
                    <Button
                        variant="contained"
                        startIcon={<Save />}
                        onClick={() => formik.handleSubmit()}
                        loading={formik.isSubmitting}
                    >
                        {t('Save PV Analysis (Only for Ops)')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
