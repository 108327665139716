export const INITIAL_FORM_VALUES = {
    // Allgemein
    GEFOERDERT: false,
    ENDDATUM_UMSETZUNG: '',
    ZEITRAHMEN_UMSETZUNG: '',

    GEBAUDENAME: '',
    ADRESSE: '',
    PLZ: '',
    KOMMUNE: '',

    IZ_ZAEHLERSCHRANK: '',
    AUFNAHME_IZ_ZAEHLERSCHRANK: '',

    IZ_UNTERVERTEILUNG: '',
    AUFNAHME_IZ_UNTERVERTEILUNG: '',

    IZ_SYSTEMKOMPONENTEN: '',

    IZ_NETZWERKANSCHLUSS: '',
    AUFNAHME_IZ_NETZWERKANSCHLUSS: '',

    AUFNAHME_ZAEHLERSCHRANK: '',
    AUFNAHME_ZAEHLER: [],
    AUFNAHME_ZAEHLERAUFHAENGUNG: '',
    AUFNAHME_TYP_ZAEHLERSCHRANK: '',

    AUFNAHME_IZ_WECHSELRICHTER_AND_SPEICHER: '',

    RELATIVE_POSITION_OF_THE_ROOMS_TO_EACH_OTHER: '',

    // Dach
    DACHTYP: '',
    DACHHAUT: '',
    DACHFLAECHE_POTENZIAL: '',
    ANZAHL_DACHFLAECHEN: 1,
    AUFNAHME_DROHNE_GESAMT: '',

    AUFNAHME_DACHSTUHL: [],
    AUFNAHME_SPARRENABSTAND: '',
    SPARRENABSTAND: '',
    AUFNAHME_SPARRENBREITE: '',
    SPARRENBREITE: '',
    AUFNAHME_DECKMASS_VERTIKAL: '',
    AUFNAHME_DECKMASS_HORIZONTAL: '',
    DECKMASS_VERTIKAL: '',
    DECKMASS_HORIZONTAL: '',
    AUFSPARRENDAEMMUNG: false,
    LOAD_RESERVES_VALUE: '',
    LOAD_RESERVES_UNIT: 'kN/m2',

    // Dachflaeche
    DACHFLAECHE: [
        {
            AUSRICHTUNG: '',
            POTENZIAL: '',
            DACHNEIGUNG: '',
            TRAUFHOEHE: '',
            TRAUFLAENGE: '',
            LIST_STOERF: [],
            AUFNAHME: '',
            AUFNAHME_FRONT: '',
        },
    ],

    // Kabelfuehrung
    KABELFUEHRUNG_DC: '',
    AUFNAHME_KABELFUEHRUNG_DC_AUSSEN: [],
    KABELFUEHRUNG_DC_AUSSEN: '',

    HOEHE_HAUSEINTRITT: '',
    AUFNAHME_HAUSEINTRITT: '',

    ERDARBEITEN: false,

    ANZAHL_WDB_AUSSEN: '',
    TYP_AUSSENWAND: '',
    AUFNAHME_KABELFUEHRUNG_DC_INNEN: [],
    ANZAHL_WDB_INNEN: '',

    IZ_FEUERWEHR: '',

    PEAK_LEISTUNG: '',
    BATTERIE_KAPAZITAET: '',
};
