import { BBox, MultiPolygon, Polygon, Position } from 'geojson';

export interface GeoLocation {
    address: string;
    lat: number;
    lng: number;
}

export enum Layers {
    SOLAR_POTENTIAL = 'solar-potential',
}

export interface SolarPotentialSearch {
    maxSystemCapacityAdjustment?: number;
    service?: SolarPotentialServiceType;
}

export enum SolarPotentialServiceType {
    GOOGLE = 'google',
    ADMI = 'admi',
}

export interface BuildingSearch extends SolarPotentialSearch {
    uuid: string;
}

export interface LocationSearch extends SolarPotentialSearch {
    location: {
        lat: number;
        lng: number;
    };
    radius: number;
    layers: Layers[];
}

export type RealEstateGeometry = Polygon | MultiPolygon;

export interface BuildingFunction {
    code: string;
    name: string;
}

export interface Building {
    id: string;
    code: string;
    name: string;
    box: BBox;
    geometry: RealEstateGeometry;
    buildingFunction: BuildingFunction;
    roof: Roof;
}

export interface RealEstate {
    count: number;
    locationSearch: {
        lat: number;
        lng: number;
        radius: number;
        layers: Layers[];
    };
    buildings: Building[];
}

export interface RoofSegmenetSolarPotential {
    panelsCount: number;
    panelCapacityWatts: number;
    yearlyEnergyDcKwh: number;
    moduleSizeAssumption: number;
    kwp: number;
    normalizedKwp: number;
    kwhPerKwp: number;
    dataSourceProcessedAt: Date;
    panelHeightMeters: number;
    panelWidthMeters: number;
    panelLifetimeYears: number;
}

export interface RoofSegment {
    id: string;
    code: string;
    center: Position;
    bbox: BBox;
    area: number;
    roofSegmentSolarPotential: RoofSegmenetSolarPotential;
    tiltDegrees: number;
    azimuthDegrees: number;
}

export interface Roof {
    id: string;
    code: string;
    center: Position;
    bbox: BBox;
    area: number;
    roofSegments: RoofSegment[];
    source: string;
}

export interface BuildingsMetadata {
    count: number;
    locationSearch: {
        lat: number;
        lng: number;
        radius: number;
    };
    buildingsMetadata: BuildingMetadata[];
}

export interface BuildingMetadata {
    code: string;
    functionCode: string;
    filePath: string;
    box: BBox;
    bbox2d: RealEstateGeometry;
}
