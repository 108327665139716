import React, { useRef } from 'react';
import { Box } from '@mui/joy';
import { getElementAtEvent, Scatter } from 'react-chartjs-2';

import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);

import { RoiInsights } from '../pv-calculator.dto';

export default function PVResultInsights({
    roiInsights,
    bestRateOfReturn,
    bestOwnConsumptionRatio,
    bestTargetProduction,
    onClickDataPoint,
}: {
    roiInsights: RoiInsights;
    bestRateOfReturn: number;
    bestOwnConsumptionRatio: number;
    bestTargetProduction: number;
    onClickDataPoint?: (targetProduction: number, batteryCapacityFactor: number) => void;
}) {
    const chartRef = useRef<Chart<'scatter'>>(null);

    const chartData = {
        datasets: [
            ...roiInsights.map(([batteryCapacityFactor, rois]) => ({
                label: `Rate of return @ ${batteryCapacityFactor.toFixed(2)} kWh/kWp`,
                showLine: true,
                data: rois.map(([target_production, rate_of_return]) => ({
                    x: target_production,
                    y: rate_of_return * 100,
                    batteryCapacityFactor,
                })),
                backgroundColor: 'rgba(11, 107, 203, 0.7)',
                yAxisID: 'y',
            })),
            ...roiInsights.map(([batteryCapacityFactor, rois]) => ({
                label: `Own consumption @ ${batteryCapacityFactor.toFixed(2)} kWh/kWp`,
                showLine: true,
                data: rois.map(([target_production, _, own_use]) => ({
                    x: target_production,
                    y: own_use * 100,
                    batteryCapacityFactor,
                })),
                backgroundColor: 'rgba(180, 30, 0, 0.7)',
                yAxisID: 'yOwnConsumption',
            })),
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Target production (kWh/a)',
                },
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Rate of return (%)',
                    color: 'rgb(11, 107, 203)',
                },
            },
            yOwnConsumption: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false, // Grid lines should only show up for the main axis
                },
                title: {
                    display: true,
                    text: 'Own consumption ratio (%)',
                    color: 'rgb(180, 30, 0)',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    bestRateOfReturn: {
                        type: 'line',
                        yMin: bestRateOfReturn * 100,
                        yMax: bestRateOfReturn * 100,
                        borderColor: 'rgb(11, 107, 203)',
                        borderWidth: 2,
                        borderDash: [5, 5],
                    },
                    bestOwnConsumptionRatio: {
                        type: 'line',
                        yMin: bestOwnConsumptionRatio * 100,
                        yMax: bestOwnConsumptionRatio * 100,
                        borderColor: 'rgb(180, 30, 0)',
                        borderWidth: 2,
                        borderDash: [5, 5],
                        yScaleID: 'yOwnConsumption',
                    },
                    bestTargetProduction: {
                        type: 'line',
                        xMin: bestTargetProduction,
                        xMax: bestTargetProduction,
                        borderColor: 'rgb(100, 100, 100)',
                        borderWidth: 2,
                        borderDash: [5, 5],
                    },
                },
            },
        },
    };

    function handleClick(event: React.MouseEvent<HTMLCanvasElement>) {
        const chart = chartRef.current as Chart;
        const elements = getElementAtEvent(chart, event);

        if (elements.length > 0 && onClickDataPoint) {
            const element = elements[0];
            const dataset = chartData.datasets[element.datasetIndex].data[element.index];

            onClickDataPoint(dataset.x, dataset.batteryCapacityFactor);
        }
    }

    return (
        <>
            {chartData && (
                <Box sx={{ width: '100%', height: '30rem', position: 'relative' }}>
                    <Scatter ref={chartRef} data={chartData} options={chartOptions as any} onClick={handleClick} />
                </Box>
            )}
        </>
    );
}
