import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Option,
    Select,
    Skeleton,
    Stack,
    Typography,
} from '@mui/joy';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router';
import { useAppDispatch } from 'src/redux-app-hooks';

import { downloadFileFromBlob } from 'src/common/downloadFile';
import { formFieldBuilder, handleSelect } from 'src/common/formikUtils';
import ImageSelector from 'src/common/components/ImageSelector';

import { admiFeasibilityStudyService } from './admi-feasibility-study.service';
import { INITIAL_VALUES, VALIDATION_SCHEMA } from './formData';

import { NotificationSeverity, showSnackbar } from 'src/features/notifications/notifications.slice';
import { ROOF_COVERINGS, ROOF_TYPES } from 'src/features/onsite/selectOptions';
import { BuildingType } from 'src/features/pv-calculator/pv-calculator.dto';
import { ContactAutocomplete } from 'src/features/crm/components/ContactAutocomplete';
import { Contact } from 'src/features/crm/crm.dto';
import { loadOnsiteIntoFormik } from './loadOnsiteIntoFormik';
import { ConnectionPointType, StaticAssessment } from './feasibility-study.dto';

// Select between yes/no/not set for boolean values
// to make missing values explicit
export function YesNoDropdown({ name, formik }: { name: string; formik: any }) {
    const { t } = useTranslation();
    return (
        <Select
            sx={{ width: '10rem' }}
            value={formik.getFieldProps(name).value ?? null}
            onChange={handleSelect(formik, name)}
        >
            <Option value={true}>{t('Yes')}</Option>
            <Option value={false}>{t('No')}</Option>
        </Select>
    );
}

export function FeasibilityStudyCreatePage() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        validationSchema: VALIDATION_SCHEMA,
        onSubmit: async (values) => {
            console.debug('submit feasibility study', values);

            try {
                const [documentData, filename] = await admiFeasibilityStudyService.createFeasibilityStudy(values);
                downloadFileFromBlob(documentData, filename);

                dispatch(
                    showSnackbar({
                        message: t('Feasiblity study has been successfully created.'),
                        severity: NotificationSeverity.Success,
                    }),
                );
            } catch (error: any) {
                console.error(error);
                dispatch(
                    showSnackbar({
                        message: t('Failed to create feasibility study: {{error}}', {
                            error: JSON.stringify(error?.message),
                        }),
                        severity: NotificationSeverity.Error,
                    }),
                );
            }
        },
    });
    const formField = (name: string) => formFieldBuilder(formik, name);

    function handleContactSelect(contact: Contact) {
        formik.setFieldValue('contact', {
            name: contact.name,
            address: `${contact.streetAddress}, ${contact.zipCodeAndCity}`,
            email: contact.email,
            phone: contact.phone,
            jobTitle: contact.jobTitle,
        });
    }

    // Load initial data
    const [searchParams, _] = useSearchParams();
    const [imageUrls, setImageUrls] = useState<Map<string, string[]>>(new Map());
    const imageSelectorProps = (field: string) => ({ name: field, formik, imageUrls: imageUrls.get(field) });
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const onsiteId = searchParams.get('from_onsite');
        if (!onsiteId) return;

        setIsLoading(true);
        loadOnsiteIntoFormik(onsiteId, formik)
            .then((imageUrls) => {
                console.debug('load onsite values', formik.values, imageUrls);
                setImageUrls(imageUrls);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                dispatch(
                    showSnackbar({
                        message: t('Could not load onsite data: {{error}}', { error: error.message }),
                        severity: NotificationSeverity.Error,
                    }),
                );
            });
    }, [searchParams]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack
                spacing={1.5}
                sx={{
                    display: 'flex',
                    maxWidth: '900px',
                    mx: 'auto',
                    px: { xs: 2, md: 6 },
                    py: { xs: 2, md: 3 },
                }}
            >
                <Typography level="h1">{t('Create feasibility study')}</Typography>

                {isLoading && <Skeleton variant="rectangular" height="100rem" />}
                {!isLoading && (
                    <>
                        <Typography level="h3">{t('Project information')}</Typography>
                        <FormControl required>
                            <FormLabel>{t('Municipality name')}</FormLabel>
                            <Input placeholder={t('Municipality name')} {...formField('project.municipalityName')} />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Project name')}</FormLabel>
                            <Input placeholder={t('Project name')} {...formField('project.name')} />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Project address')}</FormLabel>
                            <Input placeholder={t('Project address')} {...formField('project.address')} />
                        </FormControl>
                        <FormControl required error={!!formik.errors.project?.imageGoogleMapsScreenshot}>
                            <FormLabel>{t('Google Maps screenshot')}</FormLabel>
                            <ImageSelector name="project.imageGoogleMapsScreenshot" formik={formik} />
                            {formik.touched.project?.imageGoogleMapsScreenshot &&
                                formik.errors.project?.imageGoogleMapsScreenshot && (
                                    <FormHelperText>{formik.errors.project.imageGoogleMapsScreenshot}</FormHelperText>
                                )}
                        </FormControl>

                        <Typography level="h3">{t('Contact information')}</Typography>
                        <FormControl>
                            <FormLabel>{t('Contact search')}</FormLabel>
                            <ContactAutocomplete onSelectContact={handleContactSelect} />
                        </FormControl>

                        <FormControl required>
                            <FormLabel>{t('Contact name')}</FormLabel>
                            <Input placeholder={t('Contact name')} {...formField('contact.name')} />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Contact address')}</FormLabel>
                            <Input placeholder={t('Contact address')} {...formField('contact.address')} />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Contact email')}</FormLabel>
                            <Input type="email" placeholder={t('Contact email')} {...formField('contact.email')} />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Contact phone')}</FormLabel>
                            <Input placeholder={t('Contact phone')} {...formField('contact.phone')} />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Contact job title')}</FormLabel>
                            <Input placeholder={t('Contact job title')} {...formField('contact.jobTitle')} />
                        </FormControl>

                        <Typography level="h3">{t('Potential analysis result')}</Typography>
                        <FormControl required>
                            <FormLabel>{t('Building type')}</FormLabel>
                            <Select
                                sx={{ width: '20rem' }}
                                value={formik.values.potentialAnalysisResult.buildingType}
                                onChange={handleSelect(formik, 'potentialAnalysisResult.buildingType')}
                            >
                                {Object.values(BuildingType).map((buildingTypeName) => (
                                    <Option key={buildingTypeName} value={buildingTypeName}>
                                        {t(buildingTypeName)}
                                    </Option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Consumption (kWh/a)')}</FormLabel>
                            <Input
                                type="number"
                                placeholder={t('Consumption (kWh/a)')}
                                {...formField('potentialAnalysisResult.consumptionKwhPerYear')}
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('PV peak power (kWp)')}</FormLabel>
                            <Input
                                type="number"
                                placeholder={t('PV peak power (kWp)')}
                                {...formField('potentialAnalysisResult.pvKwp')}
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Battery capacity (kWh)')}</FormLabel>
                            <Input
                                type="number"
                                placeholder={t('Battery capacity (kWh)')}
                                {...formField('potentialAnalysisResult.batteryKwh')}
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('PV production (kWh/a)')}</FormLabel>
                            <Input
                                type="number"
                                placeholder={t('PV production (kWh/a)')}
                                {...formField('potentialAnalysisResult.productionKwhPerYear')}
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('CO2 savings')}</FormLabel>
                            <Input
                                type="number"
                                placeholder={t('CO2 savings')}
                                {...formField('potentialAnalysisResult.co2Savings')}
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Initial investment (€)')}</FormLabel>
                            <Input
                                type="number"
                                placeholder={t('Initial investment (€)')}
                                {...formField('potentialAnalysisResult.initialInvestment')}
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Balance over 30 years (€)')}</FormLabel>
                            <Input
                                type="number"
                                placeholder={t('Balance over 30 years (€)')}
                                {...formField('potentialAnalysisResult.balance30Years')}
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Rate of return (%)')}</FormLabel>
                            <Input
                                type="number"
                                placeholder={t('Rate of return (%)')}
                                {...formField('potentialAnalysisResult.rateOfReturn')}
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Amortization years')}</FormLabel>
                            <Input
                                type="number"
                                placeholder={t('Amortization years')}
                                {...formField('potentialAnalysisResult.amortizationYears')}
                            />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Self-consumption rate (%)')}</FormLabel>
                            <Input
                                type="number"
                                placeholder={t('Self-consumption rate (%)')}
                                {...formField('potentialAnalysisResult.selfConsumptionRate')}
                            />
                        </FormControl>

                        <Typography level="h3">{t('Onsite information')}</Typography>
                        <FormControl required>
                            <FormLabel>{t('Author name')}</FormLabel>
                            <Input placeholder={t('Author name')} {...formField('onsite.authorName')} />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Onsite date')}</FormLabel>
                            <Input type="date" placeholder={t('Onsite date')} {...formField('onsite.onsiteDate')} />
                        </FormControl>

                        <Stack direction="row" spacing={2}>
                            <FormControl required>
                                <FormLabel>{t('Access to utility room')}</FormLabel>
                                <YesNoDropdown name="onsite.accessToUtilityRoom" formik={formik} />
                            </FormControl>
                            <FormControl required>
                                <FormLabel>{t('Access to meter cabinet')}</FormLabel>
                                <YesNoDropdown name="onsite.accessToMeterCabinet" formik={formik} />
                            </FormControl>
                            <FormControl required>
                                <FormLabel>{t('Access to attic')}</FormLabel>
                                <YesNoDropdown name="onsite.accessToAttic" formik={formik} />
                            </FormControl>
                            <FormControl required>
                                <FormLabel>{t('Access to roof')}</FormLabel>
                                <YesNoDropdown name="onsite.accessToRoof" formik={formik} />
                            </FormControl>
                        </Stack>

                        <Typography level="h3">{t('Roof information')}</Typography>
                        <Stack direction="row" spacing={2}>
                            <FormControl required>
                                <FormLabel>{t('Roof type')}</FormLabel>
                                <Select
                                    sx={{ width: '20rem' }}
                                    value={formik.values.roof.roofType}
                                    onChange={handleSelect(formik, 'roof.roofType')}
                                >
                                    {ROOF_TYPES.map((roofType) => (
                                        <Option key={roofType} value={roofType}>
                                            {roofType}
                                        </Option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl required>
                                <FormLabel>{t('Roof covering')}</FormLabel>
                                <Select
                                    sx={{ width: '20rem' }}
                                    value={formik.values.roof.roofCovering}
                                    onChange={handleSelect(formik, 'roof.roofCovering')}
                                >
                                    {ROOF_COVERINGS.map((roofCovering) => (
                                        <Option key={roofCovering} value={roofCovering}>
                                            {roofCovering}
                                        </Option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl required>
                                <FormLabel>{t('Number of selected roof segments')}</FormLabel>
                                <Input
                                    type="number"
                                    placeholder={t('Number of selected roof segments')}
                                    {...formField('roof.numberOfSelectedRoofSegments')}
                                />
                            </FormControl>
                        </Stack>
                        <FormControl required error={!!formik.errors.roof?.imageDroneRoof}>
                            <FormLabel>{t('Roof drone photo')}</FormLabel>
                            <ImageSelector {...imageSelectorProps('roof.imageDroneRoof')} />
                            {formik.touched.roof?.imageDroneRoof && formik.errors.roof?.imageDroneRoof && (
                                <FormHelperText>{formik.errors.roof.imageDroneRoof}</FormHelperText>
                            )}
                        </FormControl>

                        <Typography level="h3">{t('Module assignment')}</Typography>
                        <FormControl required error={!!formik.errors.imagesPossibleModuleAssignment}>
                            <FormLabel>{t('Photo of possible module assignment')}</FormLabel>
                            <ImageSelector {...imageSelectorProps('imagesPossibleModuleAssignment')} multiple />
                            {formik.touched.imagesPossibleModuleAssignment &&
                                formik.errors.imagesPossibleModuleAssignment && (
                                    <FormHelperText>{formik.errors.imagesPossibleModuleAssignment}</FormHelperText>
                                )}
                        </FormControl>

                        <Typography level="h3">{t('Static requirements')}</Typography>
                        <FormControl required>
                            <FormLabel>{t('Static assessment')}</FormLabel>
                            <Select
                                value={formik.values.staticAssessment}
                                onChange={handleSelect(formik, 'staticAssessment')}
                            >
                                <Option value={StaticAssessment.NO_STATEMENT_POSSIBLE}>
                                    {t('No statement possible')}
                                </Option>
                                <Option value={StaticAssessment.LOAD_RESERVES_SUFFICIENT}>
                                    {t('Load reserves sufficient')}
                                </Option>
                                <Option value={StaticAssessment.LOAD_RESERVES_INSUFFICIENT}>
                                    {t('Load reserves insufficient')}
                                </Option>
                                <Option value={StaticAssessment.STATIC_ASSESSMENT_BY_ADMI}>
                                    {t('Static assessment by admi')}
                                </Option>
                            </Select>
                        </FormControl>

                        <Typography level="h3">{t('Cable routing')}</Typography>
                        <FormControl required>
                            <FormLabel>{t('Has suitable cable routing')}</FormLabel>
                            <YesNoDropdown name="cableRouting.hasSuitableCableRouting" formik={formik} />
                        </FormControl>
                        <FormControl required error={!!formik.errors.cableRouting?.imagesCableRouting}>
                            <FormLabel>{t('Photo of cable routing')}</FormLabel>
                            <ImageSelector {...imageSelectorProps('cableRouting.imagesCableRouting')} multiple={true} />
                            {formik.touched.cableRouting?.imagesCableRouting &&
                                formik.errors.cableRouting?.imagesCableRouting && (
                                    <FormHelperText>{formik.errors.cableRouting.imagesCableRouting}</FormHelperText>
                                )}
                        </FormControl>

                        <Typography level="h3">{t('Electric installation')}</Typography>
                        <FormControl required>
                            <FormLabel>{t('Has suitable connection point')}</FormLabel>
                            <YesNoDropdown name="electricInstallation.hasSuitableConnectionPoint" formik={formik} />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Connection point type')}</FormLabel>
                            <Select
                                sx={{ width: '20rem' }}
                                value={formik.values.electricInstallation.connectionPointType}
                                onChange={handleSelect(formik, 'electricInstallation.connectionPointType')}
                            >
                                <Option value={ConnectionPointType.LOW_VOLTAGE_MAIN_DISTRIBUTION}>
                                    {t('Low voltage main distribution')}
                                </Option>
                                <Option value={ConnectionPointType.SUB_DISTRIBUTION}>{t('Sub distribution')}</Option>
                                <Option value={ConnectionPointType.METER_CABINET}>{t('Meter cabinet')}</Option>
                            </Select>
                        </FormControl>
                        <FormControl required error={!!formik.errors.electricInstallation?.imagesMeterCabinet}>
                            <FormLabel>{t('Photo of meter cabinet')}</FormLabel>
                            <ImageSelector
                                {...imageSelectorProps('electricInstallation.imagesMeterCabinet')}
                                multiple={true}
                            />
                            {formik.touched.electricInstallation?.imagesMeterCabinet &&
                                formik.errors.electricInstallation?.imagesMeterCabinet && (
                                    <FormHelperText>
                                        {formik.errors.electricInstallation.imagesMeterCabinet}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Has suitable installation zone')}</FormLabel>
                            <YesNoDropdown name="electricInstallation.hasSuitableInstallationZone" formik={formik} />
                        </FormControl>
                        <FormControl required>
                            <FormLabel>{t('Installation room')}</FormLabel>
                            <Input
                                placeholder={t('Installation room')}
                                {...formField('electricInstallation.installationRoom')}
                            />
                        </FormControl>
                        <FormControl
                            required
                            error={!!formik.errors.electricInstallation?.imagesInstallationZoneComponents}
                        >
                            <FormLabel>{t('Photo of installation zone components')}</FormLabel>
                            <ImageSelector
                                {...imageSelectorProps('electricInstallation.imagesInstallationZoneComponents')}
                                multiple={true}
                            />
                            {formik.touched.electricInstallation?.imagesInstallationZoneComponents &&
                                formik.errors.electricInstallation?.imagesInstallationZoneComponents && (
                                    <FormHelperText>
                                        {formik.errors.electricInstallation.imagesInstallationZoneComponents}
                                    </FormHelperText>
                                )}
                        </FormControl>

                        <Typography level="h3">{t('Fire protection')}</Typography>
                        <FormControl required>
                            <FormLabel>
                                {t('Cable routing can be used without additional fire protection measures')}
                            </FormLabel>
                            <YesNoDropdown
                                name="fireProtection.hasSuitableFireProtectionCableRouting"
                                formik={formik}
                            />
                        </FormControl>
                        <FormControl error={!!formik.errors.fireProtection?.imageAreasWithFireProtectionMeasures}>
                            <FormLabel>{t('Photo of areas with fire protection measures')}</FormLabel>
                            <ImageSelector
                                {...imageSelectorProps('fireProtection.imageAreasWithFireProtectionMeasures')}
                            />
                            {formik.touched.fireProtection?.imageAreasWithFireProtectionMeasures &&
                                formik.errors.fireProtection?.imageAreasWithFireProtectionMeasures && (
                                    <FormHelperText>
                                        {formik.errors.fireProtection.imageAreasWithFireProtectionMeasures}
                                    </FormHelperText>
                                )}
                        </FormControl>
                        <FormControl required>
                            <FormLabel>
                                {t('Installation zone can be used without additional fire protection measures')}
                            </FormLabel>
                            <YesNoDropdown name="fireProtection.hasFireProtectionInstallationZone" formik={formik} />
                        </FormControl>

                        <Button type="submit" color="primary" loading={formik.isSubmitting}>
                            {t('Generate document')}
                        </Button>
                    </>
                )}
            </Stack>
        </form>
    );
}
