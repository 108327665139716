export enum Optimizer {
    ROI = 'roi',
    FULL_ROOF = 'full_roof',
    RHEINISCHES_REVIER_FUNDING_ROI = 'rheinisches_revier_funding_roi',
    RHEINISCHES_REVIER_FUNDING_MAX_PROD = 'rheinisches_revier_funding_max_prod',
}

export enum BuildingType {
    SCHULE = 'SCHULE',
    BAUHOF = 'BAUHOF',
    BUECHEREI = 'BUECHEREI',
    FEUERWEHR = 'FEUERWEHR',
    FREIBAD = 'FREIBAD',
    FRIEDHOF = 'FRIEDHOF',
    HALLENBAD = 'HALLENBAD',
    JUGENDZENTRUM = 'JUGENDZENTRUM',
    KINDERGARTEN = 'KINDERGARTEN',
    KLAERWERK = 'KLAERWERK',
    MUSEUM = 'MUSEUM',
    PUMPWERK = 'PUMPWERK',
    SPORTPLATZ_AN_SCHULE = 'SPORTPLATZ_AN_SCHULE',
    SPORTPLATZ_OHNE_SCHULE = 'SPORTPLATZ_OHNE_SCHULE',
    VERWALTUNGSGEBAEUDE = 'VERWALTUNGSGEBAEUDE',
    WASSERWERK = 'WASSERWERK',
    WOHNHEIM_FUER_GEFLUECHTETE = 'WOHNHEIM_FUER_GEFLUECHTETE',
}

export enum LoadProfileType {
    BASE = 'base',
    HEAT_PUMP = 'heat_pump',
    CUSTOM = 'custom',
}

export interface BaseLoadProfileDto {
    type: LoadProfileType.BASE;
    annual_usage: number | null;
}

export interface HeatPumpLoadProfileDto {
    type: LoadProfileType.HEAT_PUMP;
    annual_heat_demand: number;
    annual_cop: number;
}

export interface CustomLoadProfileDto {
    type: LoadProfileType.CUSTOM;
    values: number[];
}

export type LoadProfileDto = BaseLoadProfileDto | HeatPumpLoadProfileDto | CustomLoadProfileDto;

export interface RoofSegmentDto {
    id: string;
    kwp: number;
    kwh_kwp: number;
    roof_pitch: number;
    direction: number;
    building_id: string;
    sum_wb_hu?: number;
    center?: [number, number];
}

export type RoiInsights = [number, [number, number, number][]][];

export interface OneTimeCostItem {
    type: 'OneTimeCostItem';
    name: string;
    amount: number;
    year_index: number;
}
export interface YearlyCostItem {
    type: 'YearlyCostItem';
    name: string;
    amount: number;
    inflation_rate: number;
}
export interface DepreciationCostItem {
    type: 'DepreciationCostItem';
    name: string;
    amount: number;
    lifetime: number;
    inflation_rate: number;
    year_index: number;
}
export interface OneTimeProfitItem {
    type: 'OneTimeProfitItem';
    name: string;
    amount: number;
    year_index: number;
}
export type ProfitLossItem = OneTimeCostItem | YearlyCostItem | DepreciationCostItem | OneTimeProfitItem;

export interface PVResultDto {
    design_kwp: number;
    design_kwh_per_year_ac: number;
    battery_capacity: number;
    load_summary: [string, number][];
    initial_investment_incl_funding: number;
    initial_investment: number;
    balance_30_years: number;
    rate_of_return: number;
    profit_loss_accumulated: number[];
    amortization_years: number | null;
    co2_savings: number;
    trees: number;
    ratio_own_use: number;
    roi_insights?: RoiInsights;
    profit_loss_statement?: ProfitLossItem[];
    used_roof_segment_ids?: string[];
}
