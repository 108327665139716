type ImageFromDataUrl = string;

export interface CreateFeasibilityStudy {
    project: ProjectInfo;
    contact: ContactInfo;
    potentialAnalysisResult: PotentialAnalysisResult;
    onsite: OnsiteInfo;
    roof: RoofInfo;

    imagesPossibleModuleAssignment: ImageFromDataUrl[];
    staticAssessment: StaticAssessment | null;

    cableRouting: CableRoutingInfo;
    electricInstallation: ElectricInstallationInfo;
    fireProtection: FireProtectionInfo;
}

export interface ProjectInfo {
    municipalityName: string;
    name: string;
    address: string;
    imageGoogleMapsScreenshot: ImageFromDataUrl;
}

export interface ContactInfo {
    name: string;
    address: string;
    email: string;
    phone: string;
    jobTitle: string;
}

export interface PotentialAnalysisResult {
    buildingType: string;
    consumptionKwhPerYear: number;
    pvKwp: number;
    batteryKwh: number;
    productionKwhPerYear: number;
    co2Savings: number;
    initialInvestment: number;
    balance30Years: number;
    rateOfReturn: number;
    amortizationYears: number;
    selfConsumptionRate: number;
}

export interface OnsiteInfo {
    authorName: string;
    onsiteDate: Date;

    accessToUtilityRoom: boolean | null;
    accessToMeterCabinet: boolean | null;
    accessToAttic: boolean | null;
    accessToRoof: boolean | null;
}

export interface RoofInfo {
    roofType: string;
    roofCovering: string;
    numberOfSelectedRoofSegments: number;
    imageDroneRoof: ImageFromDataUrl;
}

export interface CableRoutingInfo {
    hasSuitableCableRouting: boolean | null;
    imagesCableRouting: ImageFromDataUrl[];
}

export interface ElectricInstallationInfo {
    hasSuitableConnectionPoint: boolean | null;
    connectionPointType: ConnectionPointType | null;
    imagesMeterCabinet: ImageFromDataUrl[];

    hasSuitableInstallationZone: boolean | null;
    installationRoom: string;
    imagesInstallationZoneComponents: ImageFromDataUrl[];
}

export interface FireProtectionInfo {
    hasSuitableFireProtectionCableRouting: boolean | null;
    imageAreasWithFireProtectionMeasures: ImageFromDataUrl | null;
    hasFireProtectionInstallationZone: boolean | null;
}

export enum StaticAssessment {
    NO_STATEMENT_POSSIBLE = 'NO_STATEMENT_POSSIBLE',
    LOAD_RESERVES_SUFFICIENT = 'LOAD_RESERVES_SUFFICIENT',
    LOAD_RESERVES_INSUFFICIENT = 'LOAD_RESERVES_INSUFFICIENT',
    STATIC_ASSESSMENT_BY_ADMI = 'STATIC_ASSESSMENT_BY_ADMI',
}

export enum ConnectionPointType {
    LOW_VOLTAGE_MAIN_DISTRIBUTION = 'LOW_VOLTAGE_MAIN_DISTRIBUTION',
    SUB_DISTRIBUTION = 'SUB_DISTRIBUTION',
    METER_CABINET = 'METER_CABINET',
}
