import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { Position } from 'geojson';
import { feature, flatten } from '@turf/turf';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DomainAdd } from '@mui/icons-material';

import useGoogleMapsApiLoader from 'src/infrastructure/google/useGoogleMapsApiLoader';
import { generateColorHashFromString } from 'src/common/generateColorHashFromString';
import { PvAnalysisVariationBuilding } from '../pv-analysis.dto';
import {
    loadBuildingToSelection,
    loadRealEstateData,
    removeBuildingFromSelection,
    removeRoofSegmentFromBuildingSelection,
    setHighlightedBuilding,
    setLocation,
} from '../pv-analysis.slice';
import Screenshot from './Screenshot';
import { useAppDispatch, useAppSelector } from 'src/redux-app-hooks';
import useDebouncer from 'src/common/useDebouncer';
import { buildUniqueRoofSegmentId } from '../pv-analysis.utils';

export interface TakeScreenshotOptions {
    blob: Blob;
    center: Position;
    zoom: number;
}

export interface MapOptions {
    center: Position;
    zoom: number;
}

interface Properties {
    takeScreenshot: (options: TakeScreenshotOptions) => void;
}

const LOAD_BUILDINGS_DEBOUNCE_MS = 1000;

export default function Map({ takeScreenshot }: Properties) {
    const { location, buildings, editedVariant, highlightedBuilding, showOnlySelectedBuildings, isMapLoading } =
        useAppSelector((state) => state.pvAnalysis);
    const selectedBuildings = editedVariant.buildings;
    const solarService = useAppSelector((state) => state.featureFlags.solarService);
    const dispatch = useAppDispatch();

    const [map, setMap] = useState<google.maps.Map | undefined>();
    const [marker, setMarker] = useState<google.maps.marker.AdvancedMarkerElement>();
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();
    const [roofSegmentMarkers, setRoofSegmentMarkers] = useState<google.maps.marker.AdvancedMarkerElement[]>([]);

    const [polygons, setPolygons] = useState<{
        [key: string]: google.maps.Polygon[];
    }>({});

    const [screenshotSaved, setScreenshotSaved] = useState<boolean>(false);

    const loader = useGoogleMapsApiLoader();

    const mapRef = useRef(null);
    const locationRef = useRef(location);

    const loadBuildingsDebouncer = useDebouncer();
    const { t } = useTranslation();

    useEffect(() => {
        loadMap();
    }, []);

    useEffect(() => {
        console.debug('Map.location changed');
        refreshCenter();
        refreshSearchMarker();
        setScreenshotSaved(false);
    }, [location]);

    useEffect(() => {
        console.debug('buildings changed');
        refreshPolygons();
        setScreenshotSaved(false);
    }, [buildings]);

    useEffect(() => {
        if (map !== undefined && geocoder !== undefined) {
            google.maps.event.addListener(map, 'dragend', () => dragend(map, geocoder));
        }
    }, [map, geocoder]);

    useEffect(() => {
        refreshPolygonsHighlight();
    }, [highlightedBuilding]);

    useEffect(() => {
        refreshPolygons();
        refreshRoofSegementMarkers();
    }, [selectedBuildings, showOnlySelectedBuildings, solarService, editedVariant.calculatorResults?.optimizedResult]);

    useEffect(() => {
        locationRef.current = location;

        if (!location) {
            return;
        }

        loadBuildingsDebouncer(() => {
            console.debug('RealEstateData.location', location);
            dispatch(
                loadRealEstateData({
                    location,
                    radius: 1000,
                    layers: [],
                    maxSystemCapacityAdjustment: editedVariant.maxSystemCapacityAdjustment || 100,
                }),
            );
        }, LOAD_BUILDINGS_DEBOUNCE_MS);
    }, [location, editedVariant.maxSystemCapacityAdjustment]);

    async function loadMap() {
        console.debug('loadMap');

        const mapOptions: google.maps.MapOptions = {
            mapId: 'admi-tagger',
            mapTypeId: 'satellite',
            mapTypeControl: false,
            tiltInteractionEnabled: false,
            streetViewControl: false,
            fullscreenControl: false,
            keyboardShortcuts: false,
            rotateControl: false,
            tilt: 0,
            cameraControl: false,
        };
        const mapElement = document.getElementById('map');
        if (mapElement !== null) {
            const { Map } = (await loader.importLibrary('maps')) as google.maps.MapsLibrary;

            // Since this is an async function, the location property might be a stale value
            // We therefore use a ref to access the latest version.
            if (locationRef.current) {
                mapOptions.center = { lat: locationRef.current.lat, lng: locationRef.current.lng };
                mapOptions.zoom = locationRef.current.zoom ?? 18;
            }

            setMap(new Map(mapElement, mapOptions));

            const { Geocoder } = (await loader.importLibrary('geocoding')) as google.maps.GeocodingLibrary;
            setGeocoder(new Geocoder());
        }
    }

    async function refreshPolygons() {
        console.debug('refreshPolygons');

        if (map !== undefined && buildings !== undefined) {
            // Clear existing polygons
            Object.values(polygons).forEach((polygons) => polygons.forEach((polygon) => polygon.setMap(null)));
            setPolygons({});

            // Define the color for selected buildings
            const selectedColor = 'f5cb5a';

            // Iterate over all buildings
            buildings.forEach((building) => {
                if (showOnlySelectedBuildings && !selectedBuildings.some((b) => b.code === building.code)) {
                    return;
                }
                // Determine the color: selected buildings use selectedColor, others get unique colors
                const color = selectedBuildings.some((b) => b.code === building.code)
                    ? selectedColor
                    : generateColorHashFromString(`building_${building.code}`);

                const buildingsPolygons = flatten(feature(building.geometry));
                buildingsPolygons.features.forEach((feature) => {
                    // Extract polygon paths from the feature's coordinates
                    const polygonPaths = feature.geometry.coordinates.map((ring: [number, number][]) =>
                        ring.map(([lng, lat]) => ({ lat, lng })),
                    );
                    // Create a new polygon with the determined color
                    const polygon = new google.maps.Polygon({
                        paths: polygonPaths,
                        strokeColor: `#${color}`,
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillColor: `#${color}`,
                        fillOpacity: highlightedBuilding === building.code ? 1 : 0.5,
                    });

                    // Attach event listeners for hover and click
                    attachPolygonInfoWindow(polygon, building);
                    google.maps.event.addListener(polygon, 'mouseover', () =>
                        dispatch(setHighlightedBuilding(building.code)),
                    );
                    google.maps.event.addListener(polygon, 'mouseout', () => dispatch(setHighlightedBuilding('')));
                    google.maps.event.addListener(polygon, 'click', () => {
                        console.debug('Clicked ' + building.code);
                        if (selectedBuildings.some((b) => b.code === building.code)) {
                            dispatch(removeBuildingFromSelection(building));
                        } else {
                            dispatch(
                                loadBuildingToSelection({
                                    uuid: building.code,
                                    maxSystemCapacityAdjustment: editedVariant.maxSystemCapacityAdjustment || 100,
                                    service: solarService,
                                }),
                            );
                        }
                        // Refresh polygons to update selected buildings' colors
                        refreshPolygons();
                    });

                    // Display the polygon on the map
                    polygon.setMap(map);

                    // Update state with the new polygon
                    const statePolygons = polygons;
                    if (!statePolygons[building.code]) {
                        statePolygons[building.code] = [];
                    }
                    statePolygons[building.code].push(polygon);

                    setPolygons(statePolygons);
                });
            });
        }
    }

    async function refreshRoofSegementMarkers() {
        if (!map) {
            return;
        }

        const { AdvancedMarkerElement } = (await loader.importLibrary('marker')) as google.maps.MarkerLibrary;
        roofSegmentMarkers.forEach((marker) => (marker.map = null));

        if (showOnlySelectedBuildings) {
            // we do not want to show the roof segment markers for the print screen feature
            return;
        }

        const newRoofSegmentMarkers: google.maps.marker.AdvancedMarkerElement[] = [];

        selectedBuildings.forEach((building, buildingIndex) => {
            building.roofSegments.forEach((roofSegment, roofSegmentIndex) => {
                console.debug('roofSegment', roofSegment, buildingIndex, roofSegmentIndex);
                if (!roofSegment.center) {
                    return;
                }
                const position = { lng: roofSegment.center[0], lat: roofSegment.center[1] };
                const uniqueRoofSegmentId = buildUniqueRoofSegmentId(buildingIndex, roofSegmentIndex);

                const dot = document.createElement('div');
                dot.style.width = '55px';
                dot.style.height = '15px';
                dot.style.backgroundColor =
                    editedVariant.calculatorResults?.optimizedResult.used_roof_segment_ids?.includes(
                        uniqueRoofSegmentId,
                    )
                        ? '#59a059'
                        : '#eda49f';
                dot.style.border = '1px solid black';
                dot.style.fontWeight = '700';
                dot.style.color = '#fff';
                dot.style.textAlign = 'center';

                // dot.style.borderRadius = '50%';
                dot.textContent = `${roofSegment.moduleArea ? roofSegment.moduleArea.toFixed(2) : 'N/a'} m² (${uniqueRoofSegmentId})`;

                const marker = new AdvancedMarkerElement({
                    position,
                    map,
                    content: dot,
                    gmpClickable: true,
                });

                marker.addListener('click', () => {
                    dispatch(
                        removeRoofSegmentFromBuildingSelection({
                            selectedBuilding: building,
                            roofSegment,
                        }),
                    );
                });

                newRoofSegmentMarkers.push(marker);
            });
        });

        setRoofSegmentMarkers(newRoofSegmentMarkers);
    }

    async function dragend(map: google.maps.Map, geocoder: google.maps.Geocoder): Promise<void> {
        console.debug('dragend', map, geocoder);
        if (map !== undefined && geocoder !== undefined) {
            try {
                const center = map.getCenter();
                if (!center) {
                    throw new Error('Map center is not defined');
                }

                const geocoderResponse: google.maps.GeocoderResponse = await geocoder.geocode({
                    location: center,
                });
                console.debug('geocoderResponse', geocoderResponse);

                dispatch(
                    setLocation({
                        address: geocoderResponse.results[0].formatted_address,
                        lat: center.lat(),
                        lng: center.lng(),
                    }),
                );
            } catch (e) {
                console.error('Geocoder failed due to: ' + e);
            }
        }
    }

    function refreshCenter() {
        console.debug('refreshCenter', location);

        if (map !== undefined && location) {
            const centerLatLng = new google.maps.LatLng(location.lat, location.lng);
            map.setCenter(centerLatLng);

            if (location.zoom !== undefined) {
                map.setZoom(location.zoom);
            }
        }
    }

    async function refreshSearchMarker(): Promise<void> {
        console.debug('refreshSearchMarker', location);

        if (map !== undefined && location) {
            const { AdvancedMarkerElement } = (await loader.importLibrary('marker')) as google.maps.MarkerLibrary;
            if (marker !== undefined) {
                marker.map = null;
                setMarker(marker);
            }

            setMarker(
                new AdvancedMarkerElement({
                    position: { lat: location.lat, lng: location.lng },
                    map,
                    title: location.address + '\n' + location.lat + ',' + location.lng,
                }),
            );
        }
    }

    function refreshPolygonsHighlight(): void {
        if (map !== undefined && polygons !== undefined) {
            Object.entries(polygons).forEach(([buildingCode, polygons]) => {
                polygons.forEach((polygon) =>
                    polygon.setOptions({
                        fillOpacity: buildingCode == highlightedBuilding ? 1 : 0.5,
                    }),
                );
            });
        }
    }

    function takeScreenshotWithMapProps(blob: Blob) {
        const mapCenter = map?.getCenter();
        const mapZoom = map?.getZoom();
        if (mapCenter?.lng() !== undefined && mapCenter?.lat() !== undefined && mapZoom !== undefined) {
            takeScreenshot({ blob, center: [mapCenter.lng(), mapCenter.lat()], zoom: mapZoom });
            setScreenshotSaved(true);
        }
    }

    function attachPolygonInfoWindow(polygon: google.maps.Polygon, building: PvAnalysisVariationBuilding) {
        const infoWindow = new google.maps.InfoWindow();

        google.maps.event.addListener(polygon, 'mouseover', (e: any) => {
            infoWindow.setContent(
                `<b>Building Function Code:</b> ${building.buildingFunction?.name || building.buildingFunctionCode || 'N/a'}<br/>`,
            );
            const latLgn: google.maps.LatLng = e.latLng;
            let lat = e.latLng.lat();
            lat = lat + 0.0001; // I am doing this in order to show the infoWindow a little bit above the polygon
            infoWindow.setPosition(new google.maps.LatLng(lat, latLgn.lng()));
            infoWindow.open(map);
        });

        google.maps.event.addListener(polygon, 'mouseout', () => {
            infoWindow.close();
        });
    }

    return (
        <>
            <Stack spacing={1} direction="row" sx={{ mt: 1 }}>
                <Screenshot mapRef={mapRef} disabled={screenshotSaved} takeScreenshot={takeScreenshotWithMapProps} />
                <Link to="/pv-analysis/import-building">
                    <Button startIcon={<DomainAdd />} variant="contained">
                        {t('Import Building')}
                    </Button>
                </Link>
            </Stack>

            <Box sx={{ marginTop: 1, width: '750px', aspectRatio: '1 / 1', position: 'relative' }}>
                <div ref={mapRef} id="map" style={{ height: '100%', width: '100%' }} />
                {isMapLoading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 12,
                            left: 12,
                            backgroundColor: '#ffffff88',
                            borderRadius: 8,
                            padding: 3,
                            paddingBottom: 0,
                        }}
                    >
                        <CircularProgress />
                    </div>
                )}
            </Box>
        </>
    );
}
