import { API_BASE_URL } from 'src/appConfig';
import { httpClient } from '../../infrastructure/admi-client/http-client';

export async function createTenderBoQ(projectIdOrName: number | string, templateParams: any) {
    const response = await httpClient.post(
        '/v1/tender-boqs',
        {
            project_id: typeof projectIdOrName === 'number' ? projectIdOrName : undefined,
            project_name: typeof projectIdOrName === 'string' ? projectIdOrName : undefined,
            boq_template_params: templateParams,
        },
        {
            baseURL: API_BASE_URL,
        },
    );

    return response.data;
}

export interface FetchTenderProjectDto {
    id: string;
    name: string;
}

export async function fetchTenderProjects(): Promise<FetchTenderProjectDto[]> {
    const response = await httpClient.get<{ data: FetchTenderProjectDto[] }>('/v1/tender-projects', {
        baseURL: API_BASE_URL,
    });

    return response.data.data;
}
