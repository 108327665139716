import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Pagination,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useSearchParams } from 'react-router';

import { asGermanDateTime } from 'src/common/date';
import { safeDate } from 'src/common/helpers';
import { useAppDispatch, useAppSelector } from 'src/redux-app-hooks';
import { loadOnsiteTemplates } from './onsite-visit.slice';
import { OnsiteVisitDto, OnsiteVisitSearchDto, OnsiteVisitsResponseDto } from './onsite-visit.dto';
import { admiOnsiteVisitService } from './onsite-visit.service';

function OnsiteVisitListPage() {
    const [onsiteVisits, setOnsiteVisits] = useState<OnsiteVisitsResponseDto>();
    const [isLoading, setIsLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [status, setStatus] = useState<string | null>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
    const [visitDateFrom, setVisitDateFrom] = useState<Dayjs | null>(null);
    const [visitDateTo, setVisitDateTo] = useState<Dayjs | null>(null);
    const [visitSearchParams, setVisitSearchParams] = useSearchParams();

    const dispatch = useAppDispatch();
    const templates = useAppSelector((state) => state.onsiteVisit.templates);
    const { t } = useTranslation();

    useEffect(() => {
        const controller = new AbortController();
        (async () => {
            try {
                await dispatch(loadOnsiteTemplates());

                if (!visitSearchParams.has('page')) {
                    setVisitSearchParams(
                        (prev) => {
                            const params = new URLSearchParams(prev);
                            params.set('page', '1');
                            params.set('size', '10');
                            params.set('sort', 'visitDate,desc');
                            return params;
                        },
                        { replace: true },
                    );
                }

                if (!controller.signal.aborted) {
                    setReady(true);
                }
            } catch (err) {
                if (controller.signal.aborted) return;
                console.error('Effect error:', err);
            }
        })();

        return () => {
            controller.abort(); // cancel if component unmounts
        };
    }, [dispatch]);

    useEffect(() => {
        if (!ready) return;

        setSelectedTemplate(visitSearchParams.get('templateId') ?? '');
        setStatus(visitSearchParams.get('status') ?? '');

        const from = visitSearchParams.get('visitDateFrom');
        const to = visitSearchParams.get('visitDateTo');
        setVisitDateFrom(from ? dayjs(from) : null);
        setVisitDateTo(to ? dayjs(to) : null);

        loadOnsiteVisits();
    }, [visitSearchParams, ready]);

    async function loadOnsiteVisits() {
        setIsLoading(true);

        const searchParams: OnsiteVisitSearchDto = {
            page: Number(visitSearchParams.get('page')) || 1,
            size: Number(visitSearchParams.get('size')) || 10,
            sort: visitSearchParams.get('sort') || 'visitDate,desc',
            templateId: visitSearchParams.get('templateId')?.trim() || null,
            status: visitSearchParams.get('status')?.trim() || null,
            visitDateFrom: safeDate(visitSearchParams.get('visitDateFrom')),
            visitDateTo: safeDate(visitSearchParams.get('visitDateTo')),
        };

        try {
            const response = await admiOnsiteVisitService.getOnsiteVisits(searchParams);
            setOnsiteVisits(response);
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    function changePage(page: number) {
        setVisitSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            params.set('page', page.toString());
            return params;
        });
    }

    function handleSearch() {
        setVisitSearchParams(
            (prev) => {
                const params = new URLSearchParams(prev);
                params.set('page', '1');

                selectedTemplate ? params.set('templateId', selectedTemplate) : params.delete('templateId');
                status ? params.set('status', status) : params.delete('status');

                if (visitDateFrom) params.set('visitDateFrom', visitDateFrom.format('YYYY-MM-DD'));
                else params.delete('visitDateFrom');

                if (visitDateTo) params.set('visitDateTo', visitDateTo.format('YYYY-MM-DD'));
                else params.delete('visitDateTo');

                return params;
            },
            { replace: true },
        );
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    alignItems="flex-start"
                    flexWrap="wrap"
                    useFlexGap
                    mt={2}
                >
                    <FormControl sx={{ minWidth: 200, flex: 1 }}>
                        <InputLabel id="template-label" shrink>
                            {t('Template')}
                        </InputLabel>
                        <Select
                            labelId="template-label"
                            label={t('Template')}
                            value={selectedTemplate ?? ''}
                            onChange={(e) => setSelectedTemplate(e.target.value || null)}
                            input={<OutlinedInput label={t('Template')} size="small" notched />}
                        >
                            <MenuItem value="">{t('All Templates')}</MenuItem>
                            {templates.map((template) => (
                                <MenuItem key={template.id} value={template.id}>
                                    {template.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 200, flex: 1 }}>
                        <InputLabel id="status-label" shrink>
                            {t('Status')}
                        </InputLabel>
                        <Select
                            labelId="status-label"
                            label={t('Status')}
                            value={status ?? ''}
                            onChange={(e) => setStatus(e.target.value || null)}
                            input={<OutlinedInput label={t('Status')} size="small" notched />}
                        >
                            <MenuItem value="">{t('All Statuses')}</MenuItem>
                            <MenuItem value="open">{t('Open')}</MenuItem>
                            <MenuItem value="taken">{t('Taken')}</MenuItem>
                            <MenuItem value="closed">{t('Closed')}</MenuItem>
                            <MenuItem value="cant_do">{t('Cannot do')}</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 200, flex: 1 }}>
                        <DatePicker
                            label={t('Visit Date From')}
                            value={visitDateFrom}
                            onChange={(newVal) => setVisitDateFrom(newVal)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    size: 'small',
                                    InputLabelProps: { shrink: true },
                                },
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{ minWidth: 200, flex: 1 }}>
                        <DatePicker
                            label={t('Visit Date To')}
                            value={visitDateTo}
                            onChange={(newVal) => setVisitDateTo(newVal)}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    size: 'small',
                                    InputLabelProps: { shrink: true },
                                },
                            }}
                        />
                    </FormControl>

                    <Box sx={{ minWidth: 150, alignSelf: 'stretch', display: 'flex', alignItems: 'flex-end' }}>
                        <Button variant="contained" onClick={handleSearch} fullWidth>
                            {t('Search')}
                        </Button>
                    </Box>
                </Stack>

                <Box mt={4}>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <Table>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor: '#fbfcfe',
                                        height: 10,
                                    }}
                                >
                                    {['Title', 'Created at', 'Assigned to', 'Actions'].map((label) => (
                                        <TableCell
                                            key={label}
                                            sx={{
                                                fontWeight: 'bold',
                                                color: '#1e293b',
                                                py: 1,
                                                fontSize: '0.875rem',
                                            }}
                                        >
                                            {t(label)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {onsiteVisits?.content.map((visit: OnsiteVisitDto) => (
                                    <TableRow key={visit.externalId}>
                                        <TableCell>{visit.title}</TableCell>
                                        <TableCell>{visit.visitDate && asGermanDateTime(visit.visitDate)}</TableCell>
                                        <TableCell>{visit.assignedToEmail}</TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={1}>
                                                <Link to={`/dev/onsite-visit/${visit.id}/submit`}>
                                                    <Button variant="outlined">{t('Submit Form')}</Button>
                                                </Link>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </Box>

                {!!onsiteVisits?.content.length && (
                    <Box mt={4} display="flex" justifyContent="center">
                        <Pagination
                            variant="outlined"
                            count={onsiteVisits.totalPages}
                            page={Number(visitSearchParams.get('page') || 1)}
                            onChange={(_, page) => changePage(page)}
                        />
                    </Box>
                )}
            </Box>
        </LocalizationProvider>
    );
}

export default connect()(OnsiteVisitListPage);
