import React, { Fragment } from 'react';
import { CheckBoxOutlined } from '@mui/icons-material';

import { formatToGermanNumber } from 'src/common/toGermanNumber';
import SalesChart from './SalesChart';
import { Optimizer, PVResultDto } from 'src/features/pv-calculator/pv-calculator.dto';

import './sales.css';

export const SALES_RESULT_WIDTH = 1260;
export const SALES_RESULT_HEIGHT = 583;

export default function SalesResult({
    result,
    fundingRate,
    optimizer,
}: {
    result: PVResultDto;
    fundingRate: number;
    optimizer: Optimizer;
}) {
    return (
        <div>
            <div className="subtitle">
                <h4 className="section_headline" key="section_headline">
                    {optimizer !== Optimizer.FULL_ROOF && (
                        <div>
                            <div className="logo-optimized-text">
                                <img src="/img/logo_with_text.svg" /> optimiert
                            </div>
                            {[
                                Optimizer.RHEINISCHES_REVIER_FUNDING_ROI,
                                Optimizer.RHEINISCHES_REVIER_FUNDING_MAX_PROD,
                            ].includes(optimizer) && (
                                <Fragment>
                                    <span className="quote">Förderquote: {formatToGermanNumber(fundingRate, 2)}%</span>
                                    <span className="funding">
                                        <span>
                                            80% Eigenverbrauch &nbsp;
                                            <CheckBoxOutlined htmlColor="#f5cb5b" />
                                        </span>
                                        <span>
                                            Batteriespeicher &lt; 2 x PV Leistung &nbsp;
                                            <CheckBoxOutlined htmlColor="#f5cb5b" />
                                        </span>
                                    </span>
                                </Fragment>
                            )}
                        </div>
                    )}

                    {optimizer === Optimizer.FULL_ROOF && <div>Vollbelegung</div>}
                </h4>
            </div>
            <section>
                <div>
                    <h5>Energieübersicht</h5>
                    <table>
                        <tbody>
                            <tr>
                                <td>Produktion:</td>
                                <td>{formatToGermanNumber(result.design_kwh_per_year_ac, 0)} kWh/a</td>
                            </tr>
                            <tr>
                                <td>Leistung:</td>
                                <td> {formatToGermanNumber(result.design_kwp, 2)} kWp</td>
                            </tr>
                            <tr>
                                <td>Speicher:</td>
                                <td>{formatToGermanNumber(result.battery_capacity, 2)} kWh</td>
                            </tr>
                            <tr>
                                <td>
                                    CO<sub>2</sub>-Reduktion:
                                    <div className="yearlyLabel">(jährlich)</div>
                                </td>
                                <td>
                                    {formatToGermanNumber(result.co2_savings, 1)} t
                                    <br />≈{formatToGermanNumber(result.trees, 0)} Bäume
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <h5>Finanzübersicht</h5>

                    <table>
                        <tbody>
                            {result.initial_investment > 0 && (
                                <tr>
                                    <td>Investitionskosten:</td>
                                    <td>{formatToGermanNumber(result.initial_investment, 2)} €</td>
                                </tr>
                            )}
                            {fundingRate > 0 && (
                                <tr>
                                    <td>Eigenanteil:</td>
                                    <td>{formatToGermanNumber(result.initial_investment_incl_funding, 2)} €</td>
                                </tr>
                            )}
                            <tr>
                                <td>Gesamteinsparung:</td>
                                <td>{formatToGermanNumber(result.balance_30_years, 2)} €</td>
                            </tr>
                            <tr>
                                <td>Rendite:</td>
                                <td>
                                    {(result.rate_of_return > 0 ? '+' : '') +
                                        formatToGermanNumber(result.rate_of_return * 100, 1)}{' '}
                                    %
                                </td>
                            </tr>
                            <tr>
                                <td>Amortisationszeit:</td>
                                {result.amortization_years !== null && (
                                    <td>
                                        {result.amortization_years} Jahr
                                        {result.amortization_years === 1 ? '' : 'e'}
                                    </td>
                                )}
                                {result.amortization_years === null && <td>n/a</td>}
                            </tr>
                            <tr>
                                <td>Eigenverbrauchsquote:</td>
                                <td>{formatToGermanNumber(result.ratio_own_use * 100, 0)} %</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div>
                    <SalesChart variant={result.profit_loss_accumulated} />
                </div>
            </section>
        </div>
    );
}
