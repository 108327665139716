import { Alert, Button, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import { PvAnalysisVariationBuilding, PvAnalysisVariationRoofSegment } from '../pv-analysis.dto';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import {
    addBuildingToSelection,
    removeBuildingFromSelection,
    removeRoofSegmentFromBuildingSelection,
    updateSelectedBuilding,
} from '../pv-analysis.slice';
import { useAppDispatch, useAppSelector } from 'src/redux-app-hooks';
import { generateSimpleID } from 'src/common/generateSimpleID';
import { Position } from 'geojson';
import { destination, polygon } from '@turf/turf';
import { buildUniqueRoofSegmentId } from '../pv-analysis.utils';

export default function SelectedBuildings() {
    const { editedVariant, isMapLoading, location } = useAppSelector((state) => state.pvAnalysis);
    const selectedBuildings = editedVariant.buildings;

    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const EMPTY_ROOF_SEGMENT: PvAnalysisVariationRoofSegment = {
        azimuthDegrees: 0,
        tiltDegrees: 0,
        kwhPerKwp: 0,
        kwp: 0,
        center: [0, 0],
        moduleArea: 0,
    };

    const EMPTY_BUILDING: PvAnalysisVariationBuilding = {
        code: generateSimpleID(),
        name: `Virtual Building ${selectedBuildings.length + 1}`,
        geometry: {
            type: 'Polygon',
            coordinates: [],
        },
        buildingFunction: {
            name: '',
            code: '',
        },
        roofSegments: [EMPTY_ROOF_SEGMENT],
        source: 'admi',
    };

    function addEmptyBuilding() {
        const building = EMPTY_BUILDING;
        building.geometry = generatePolygon([location?.lng ?? 0, location?.lat ?? 0], 150).geometry;
        dispatch(addBuildingToSelection(building));
    }

    function addRoofSegmentToBuilding(building: PvAnalysisVariationBuilding) {
        dispatch(
            updateSelectedBuilding({
                ...building,
                roofSegments: [
                    ...building.roofSegments,
                    {
                        ...EMPTY_ROOF_SEGMENT,
                    },
                ],
            }),
        );
    }

    function updateRoofSegment(
        building: PvAnalysisVariationBuilding,
        roofSegmentIndex: number,
        changes: Partial<PvAnalysisVariationRoofSegment>,
    ) {
        dispatch(
            updateSelectedBuilding({
                ...building,
                roofSegments: building.roofSegments.map((roofSegment, index) => {
                    if (index === roofSegmentIndex) {
                        return {
                            ...roofSegment,
                            ...changes,
                        };
                    }
                    return roofSegment;
                }),
            }),
        );
    }

    function generatePolygon(center: Position, area: number, sides = 6) {
        if (sides < 3) throw new Error('Polygon must have at least 3 sides');

        // Calculate circumradius using the area formula for a regular polygon
        const radius = Math.sqrt(area / (sides * Math.tan(Math.PI / sides))); // in meters

        const coords = [];
        for (let i = 0; i < sides; i++) {
            const angle = (i * 360) / sides;
            const point = destination(center, radius, angle, { units: 'meters' });
            coords.push(point.geometry.coordinates);
        }
        // Close the polygon by repeating the first point
        coords.push(coords[0]);

        return polygon([coords]);
    }

    return (
        <Grid container spacing={3}>
            {isMapLoading && <CircularProgress />}
            <Grid size={12} container spacing={3} justifyContent="right">
                <Button variant="contained" onClick={() => addEmptyBuilding()} color="primary">
                    {t('Add Building')}
                </Button>
            </Grid>

            {selectedBuildings.length === 0 && (
                <Grid size={12}>
                    <Alert>{t('Please, select or add a building')}.</Alert>
                </Grid>
            )}

            <Grid size={12} container spacing={3}>
                {selectedBuildings.map((building, buildingIndex: number) => (
                    <Grid key={building.code} size={12} container>
                        <Grid size={12} direction="row" container>
                            <Grid size={11.5}>
                                <Typography variant="h4">
                                    {t('Building')} #{buildingIndex + 1} ({building.code})
                                </Typography>
                            </Grid>
                            <Grid size={0.5}>
                                <IconButton
                                    aria-label={t('Remove building from this PV Analysis')}
                                    onClick={() => dispatch(removeBuildingFromSelection(building))}
                                >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid size={12} direction="row" container>
                            <Grid size={2}>
                                <b>{t('Name')}:</b>
                            </Grid>
                            <Grid size={4}>{building.name || 'N/a'}</Grid>
                            <Grid size={2}>
                                <b>{t('Building Function')}:</b>
                            </Grid>
                            <Grid size={4}>
                                {building.buildingFunction?.name || building.buildingFunctionCode || 'N/a'}
                            </Grid>
                        </Grid>

                        <Grid size={12} direction="row" container>
                            <Grid size={2}>
                                <b>{t('Source')}:</b>
                            </Grid>
                            <Grid size={4}>{building.source || 'N/a'}</Grid>
                        </Grid>

                        {building.roofSegments.map((roofSegment, roofSegmentIndex) => (
                            <Grid
                                size={12}
                                direction="row"
                                container
                                key={roofSegmentIndex}
                                sx={{
                                    backgroundColor:
                                        editedVariant.calculatorResults?.optimizedResult.used_roof_segment_ids?.includes(
                                            buildUniqueRoofSegmentId(buildingIndex, roofSegmentIndex),
                                        )
                                            ? 'success.softActiveBg'
                                            : 'inherit',
                                    pb: 1,
                                    mb: 1,
                                }}
                            >
                                <Grid size={12} direction="row" container>
                                    <Grid size={11.5}>
                                        <Typography variant="h5">
                                            {t('Roof Segement')} #{roofSegmentIndex + 1}
                                        </Typography>
                                    </Grid>
                                    <Grid size={0.5}>
                                        <IconButton
                                            aria-label={t('Remove roof segment from this PV Analysis')}
                                            onClick={() =>
                                                dispatch(
                                                    removeRoofSegmentFromBuildingSelection({
                                                        selectedBuilding: building,
                                                        roofSegment,
                                                    }),
                                                )
                                            }
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                <Grid size={12} direction="row" container>
                                    <Grid size={2}>
                                        <b>{t('Module Area')}:</b>
                                    </Grid>
                                    <Grid size={4}>
                                        {roofSegment.moduleArea ? roofSegment.moduleArea.toFixed(2) : 'N/a'} m²
                                    </Grid>
                                </Grid>

                                <Grid size={12} direction="row" container>
                                    <Grid size={6}>
                                        <TextField
                                            required
                                            label={t('Azimuth') + ' (°)'}
                                            type="number"
                                            variant="standard"
                                            size="small"
                                            placeholder={t('Azimuth')}
                                            slotProps={{ htmlInput: { min: 0, max: 360, step: 0.01 } }}
                                            defaultValue={roofSegment.azimuthDegrees.toFixed(1)}
                                            onChange={(e) =>
                                                updateRoofSegment(building, roofSegmentIndex, {
                                                    azimuthDegrees: parseFloat(e.target.value),
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid size={6}>
                                        <TextField
                                            required
                                            label={t('Tilt') + ' (°)'}
                                            type="number"
                                            variant="standard"
                                            size="small"
                                            placeholder={t('Tilt')}
                                            slotProps={{ htmlInput: { min: 0, max: 90, step: 0.01 } }}
                                            defaultValue={roofSegment.tiltDegrees.toFixed(1)}
                                            onChange={(e) =>
                                                updateRoofSegment(building, roofSegmentIndex, {
                                                    tiltDegrees: parseFloat(e.target.value),
                                                })
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid size={12} direction="row" container>
                                    <Grid size={6}>
                                        <TextField
                                            required
                                            label={t('Peak Power (kWp)')}
                                            type="number"
                                            variant="standard"
                                            size="small"
                                            placeholder={t('kWp')}
                                            slotProps={{ htmlInput: { min: 0, step: 0.01 } }}
                                            defaultValue={roofSegment.kwp.toFixed(2)}
                                            onChange={(e) =>
                                                updateRoofSegment(building, roofSegmentIndex, {
                                                    kwp: parseFloat(e.target.value),
                                                })
                                            }
                                        />
                                    </Grid>

                                    <Grid size={6}>
                                        <TextField
                                            required
                                            label={t('Specific Yield (kWh/kWp)')}
                                            type="number"
                                            variant="standard"
                                            size="small"
                                            placeholder={t('kWh/kWp')}
                                            slotProps={{ htmlInput: { min: 1, step: 0.01 } }}
                                            defaultValue={roofSegment.kwhPerKwp.toFixed(2)}
                                            onChange={(e) =>
                                                updateRoofSegment(building, roofSegmentIndex, {
                                                    kwhPerKwp: parseFloat(e.target.value),
                                                })
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid size={12} alignContent="center">
                            <Button variant="contained" onClick={() => addRoofSegmentToBuilding(building)}>
                                {t('Add roof segment')}
                            </Button>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
