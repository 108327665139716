import { useEffect, useRef, useState } from 'react';
import { Grid, Textarea, Select, Option } from '@mui/joy';
import useGoogleMapsApiLoader from 'src/infrastructure/google/useGoogleMapsApiLoader';
import { convertEpsg25832toEpsg4326, identifyCoordinateSystem } from 'src/common/geometry-conversions';

function CoordinatesToMap() {
    const [location, setLocation] = useState<{ lng: number; lat: number }>({ lng: 6.802931999999999, lat: 51.2108431 });
    const [map, setMap] = useState<google.maps.Map | undefined>();
    const [coordinates, setCoordinates] = useState<string>('');
    const [polygon, setPolygon] = useState<google.maps.Polygon | undefined>();

    const [coordinateType, setCoordinateType] = useState<string>('EPSG:4326');

    const loader = useGoogleMapsApiLoader();

    const mapRef = useRef(null);

    useEffect(() => {
        console.debug('Map');
        loadMap();
    }, []);

    useEffect(() => {
        if (!map || coordinates === '') {
            if (polygon) {
                polygon.setMap(null);
                setPolygon(undefined);
            }
            return;
        }

        console.debug('Coordinates', coordinates);
        if (polygon) {
            polygon.setMap(null);
            setPolygon(undefined);
        }

        try {
            const geoJson = eval('(' + coordinates + ')'); // this way we can just copy from debugger and paste it here

            const transformCoordinates = (coords: [number, number]): { lat: number; lng: number } => ({
                lat: coords[1],
                lng: coords[0],
            });

            const createPolygon = (path: { lat: number; lng: number }[]) => {
                const polygon = new google.maps.Polygon({
                    paths: path,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#FF0000',
                    fillOpacity: 0.35,
                });
                polygon.setMap(map);
                setPolygon(polygon);
            };

            const handlePolygon = (geometry: any) => {
                setCoordinateType(identifyCoordinateSystem(geometry.coordinates[0][0]));
                let path = geometry.coordinates[0].map(transformCoordinates);

                if (coordinateType === 'EPSG:25832') {
                    geometry = convertEpsg25832toEpsg4326(geometry);
                    path = geometry.coordinates[0].map(transformCoordinates);
                }

                createPolygon(path);
            };

            if (geoJson.type === 'Polygon' && Array.isArray(geoJson.coordinates)) {
                handlePolygon(geoJson);
            } else if (geoJson.type === 'GeometryCollection' && Array.isArray(geoJson.geometries)) {
                geoJson.geometries.forEach((geometry: any) => {
                    if (geometry.type === 'Polygon' && Array.isArray(geometry.coordinates)) {
                        handlePolygon(geometry);
                    } else {
                        console.error('Invalid GeoJSON format. Must be a Polygon with coordinates.');
                    }
                });
            } else {
                console.error('Invalid GeoJSON format. Must be a Polygon or GeometryCollection with coordinates.');
            }
        } catch (error) {
            console.error('Error parsing GeoJSON:', error);
        }
    }, [coordinates, coordinateType]);

    useEffect(() => {
        refreshCenter();
    }, [location]);

    useEffect(() => {
        if (polygon !== undefined) {
            const bounds = new google.maps.LatLngBounds();
            polygon.getPath().forEach((latLng) => {
                bounds.extend(latLng);
            });
            setLocation({ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() });
        }
    }, [polygon]);

    async function loadMap() {
        console.debug('loadMap');

        const mapOptions = {
            zoom: 18,
            center: { lat: location.lat, lng: location.lng },
            mapId: 'admi-tagger',
            mapTypeId: 'satellite',
            mapTypeControl: false,
            tilt: 0,
            tiltInteractionEnabled: false,
        };
        const mapElement = document.getElementById('map');
        if (mapElement !== null) {
            const { Map } = (await loader.importLibrary('maps')) as google.maps.MapsLibrary;
            setMap(new Map(mapElement, mapOptions));
        }
    }

    async function refreshCenter(): Promise<void> {
        console.debug('refreshCenter', location);

        if (map !== undefined) {
            const centerLatLng = new google.maps.LatLng(location.lat, location.lng);
            map.setCenter(centerLatLng);
        }
    }

    return (
        <Grid xs={12} container className="map" direction="row" justifyContent="center" spacing={2}>
            <Grid xs={12} style={{ height: '60%', width: '70%' }}>
                <div ref={mapRef} id="map" style={{ height: '100%', width: '100%' }} />
            </Grid>

            <Grid xs={12} container justifyContent="center">
                <Select
                    sx={{ width: '70%' }}
                    value={coordinateType}
                    onChange={(_event, newValue) => {
                        setCoordinateType(newValue ? newValue.toString() : '');
                    }}
                    defaultValue="EPSG:4326"
                >
                    <Option value="EPSG:25832">EPSG:25832</Option>
                    <Option value="EPSG:4326">EPSG:4326</Option>
                </Select>
            </Grid>

            <Grid xs={12} container justifyContent="center">
                <Textarea
                    minRows={10}
                    sx={{ width: '70%' }}
                    onChange={(event) => setCoordinates(event.target.value)}
                    placeholder="Enter GeoJSON coordinates"
                />
            </Grid>
        </Grid>
    );
}

export default CoordinatesToMap;
