export function ifNotNull<T>(value: T | null | undefined, callback: (value: T) => void) {
    if (value !== null && value !== undefined) {
        callback(value);
    }
}

/**
 * Appends a list of values to a map of lists.
 * @param map Map to append the elements to
 * @param key Key to append
 * @param values Values to append
 */
export function appendListInMap<T>(map: Map<string, T[]>, key: string, values: T[]) {
    const list = map.get(key) ?? [];
    list.push(...values);
    map.set(key, list);
}

export const safeDate = (value: string | null) => (value && !isNaN(new Date(value).getTime()) ? value : null);
