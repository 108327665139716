import { useEffect } from 'react';
import { useBlocker } from 'react-router';

/**
 * Blocks navigation with a confirmation dialog when either
 * 1) the user tries to navigate away from the page within the SPA
 * 2) the tab/window is closed
 *
 * @param message The message to show in the confirmation dialog. In case (2), the browser may ignore this message.
 */
export function useNavigateBlocker(message: string, blocks: () => boolean) {
    const blocker = useBlocker(() => blocks() && !confirm(message));

    function handleBeforeUnload(event: BeforeUnloadEvent) {
        event.preventDefault();
        event.returnValue = '';
        return true;
    }
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);

    return blocker;
}
